import { Icon } from '@chakra-ui/react'
import { FunctionComponent } from 'react'

import LogoIcon from 'assets/logo.svg?component'
import Link from 'components/elements/Link'
import useRoutePaths from 'hooks/useRoutePaths'

/**
 * Used for E2E tests.
 */
const TAG = 'Logo'

const Logo: FunctionComponent = () => {
  const getRoute = useRoutePaths()

  return (
    <Link display="inline-block" href={getRoute('home')} aria-label="Normet" data-testid={TAG}>
      <Icon aria-hidden="true" width={{ base: 77, md: 116 }} height="auto" as={LogoIcon} display="flex" />
    </Link>
  )
}

Logo.displayName = TAG

export default Logo
