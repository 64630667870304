import { Link, ListItem } from '@chakra-ui/react'
import { setCookie } from 'cookies-next'
import NextLink from 'next/link'
import React, { FunctionComponent, MouseEventHandler, useCallback } from 'react'

import { DEFAULT_LANGUAGE, LOCALES } from 'config/i18n.config'
import { RoutePaths } from 'config/routes'
import { CHINESE_SIMPLIFIED_LOCALE } from 'utils/getLocale'
import normalizeUrl from 'utils/normalizeUrl'

interface PageCountryLinkProps extends Contentful.IPageCountryFields {
  language: string
  closeDrawer: VoidFunction
}

/**
 * Used for E2E tests.
 */
const TAG = 'PageCountryLink'

const getCountryCode = (locale: string): string => {
  return LOCALES.includes(locale) ? locale : locale === CHINESE_SIMPLIFIED_LOCALE ? 'cn' : DEFAULT_LANGUAGE
}

const PageCountryLink: FunctionComponent<PageCountryLinkProps> = ({
  heading,
  language,
  countryCode,
  countryName,
  closeDrawer,
}) => {
  const [localeName, lang] = language.split('|')

  const locale = getCountryCode(lang)

  const href = normalizeUrl(`/${getCountryCode(locale)}/${RoutePaths.home}`)

  const onLinkClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      const dataLocale = (event.target as HTMLAnchorElement)?.getAttribute('data-locale')
      const locale = dataLocale || DEFAULT_LANGUAGE
      if (LOCALES.includes(locale)) {
        setCookie('NEXT_LOCALE', locale)
        setCookie('NEXT_REGION', countryCode)
      }
      closeDrawer()
    },
    [closeDrawer, countryCode],
  )
  return (
    <ListItem mb="4">
      <Link
        as={NextLink}
        variant="primary"
        href={href}
        locale={locale}
        onClick={onLinkClick}
        replace={true}
        data-country-code={countryCode}
        data-locale={locale}
      >
        {heading || countryName} {localeName ? `(${localeName})` : null}
      </Link>
    </ListItem>
  )
}

PageCountryLink.displayName = TAG

export default PageCountryLink
