import { useContext } from 'react'

import normalizeUrl from 'utils/normalizeUrl'
import { DynamicPageContext } from 'utils/Providers/DynamicPage/context'

export function findPathnameInTree(
  tree: ReadonlyArray<DynamicPaths.ItemWithChildren>,
  id: string | undefined,
  parentSlug = '',
): string | undefined {
  if (!tree?.length || !id) {
    return undefined
  }

  for (const item of tree) {
    const pathname = parentSlug ? `${parentSlug}/${item.slug}/` : `/${item.slug}/`
    if (item.id === id) {
      return normalizeUrl(pathname)
    } else if (item.children) {
      const found = findPathnameInTree(item.children, id, pathname)
      if (found) {
        return normalizeUrl(found)
      }
    }
  }
}

type ResolveDynamicPage = (id: string | undefined) => string

/**
 * Resolve a dynamic page link to a pathname based on the id.
 * This hook can be used only within the DynamicPageProvider.
 *
 * @example
 * ```tsx
 * const Component = () => {
 *   const resolveLink = useResolveDynamicPage()
 *
 *   return <Link to={resolveLink(sys.id)}>content</Link>
 * }
 * ```
 */
function useResolveDynamicPage(defaultUrl = '#'): ResolveDynamicPage {
  const { tree } = useContext(DynamicPageContext)

  function resolveLink(id: string | undefined) {
    return findPathnameInTree(tree, id) || defaultUrl
  }

  return resolveLink
}

export default useResolveDynamicPage
