import { Box, Flex, Icon, List, ListItem } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useContext, FunctionComponent, ReactNode } from 'react'

import FacebookIcon from 'assets/facebook.svg?component'
import InstagramIcon from 'assets/instagram.svg?component'
import LinkedInIcon from 'assets/linkedin.svg?component'
import TwitterIcon from 'assets/twitter.svg?component'
import Image from 'components/elements/Image'
import Link from 'components/elements/Link'
import { GlobalPropsContext } from 'utils/Providers/GlobalProps/context'
import removeEmptyValues from 'utils/removeEmptyValues'

/**
 * Used for E2E tests.
 */
const TAG = 'SocialMedia'

const getSocialMediaIcon = (link: Contentful.IComponentNavigationItemFields): ReactNode => {
  if (link.predefinedIcon?.[0]) {
    switch (link.predefinedIcon[0]) {
      case 'Facebook':
        return <Icon aria-hidden="true" as={FacebookIcon} boxSize={6} />
      case 'Instagram':
        return <Icon aria-hidden="true" as={InstagramIcon} boxSize={6} />
      case 'LinkedIn':
        return <Icon aria-hidden="true" as={LinkedInIcon} boxSize={6} />
      case 'Twitter':
        return <Icon aria-hidden="true" as={TwitterIcon} boxSize={6} />
    }
  } else if (link?.icon?.url) {
    return <Image src={link.icon.url} alt={link.icon?.title} width={24} height={24} />
  }
  return null
}

const SocialMedia: FunctionComponent = () => {
  const { t } = useTranslation()
  const { footerSocialMediaLinks } = useContext(GlobalPropsContext)

  return (
    <Box as="nav" aria-label={t('components.footer.socialsLabel')}>
      <Flex data-testid={TAG} as={List} direction="row" gap={4} mt={8}>
        {removeEmptyValues(footerSocialMediaLinks?.items)?.map(
          (link) =>
            link.targetUrl && (
              <ListItem key={link.targetUrl}>
                <Link
                  color="secondary.grey.300"
                  _hover={{ color: 'red' }}
                  _focusVisible={{ color: 'red' }}
                  href={link.targetUrl}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={link.title}
                >
                  {getSocialMediaIcon(link)}
                </Link>
              </ListItem>
            ),
        )}
      </Flex>
    </Box>
  )
}

SocialMedia.displayName = TAG

export default SocialMedia
