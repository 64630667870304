import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import normalizeUrl from 'utils/normalizeUrl'

function useCanonicalURL(): string {
  const { lang } = useTranslation()
  const { asPath } = useRouter()
  const cleanPath = asPath.split('#')[0].split('?')[0]
  const siteUrl = process.env.NEXT_PUBLIC_SITE_URL
  // Non-home page should have trailing slash.
  const pathname = siteUrl + normalizeUrl(`/${lang}/${cleanPath.endsWith('/') ? cleanPath : `${cleanPath}/`}`) // But home page should not have trailing slash.

  // But home page should not have trailing slash.
  const canonicalUrl = cleanPath === '/' ? `${siteUrl}/${lang}/` : pathname

  return canonicalUrl
}

export default useCanonicalURL
