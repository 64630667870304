import { Container, Divider, Flex, Link } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import NextLink from 'next/link'
import React, { FunctionComponent, memo } from 'react'

import CountryButton from 'components/modules/Header/CountryButton'
import HeaderContactButton from 'components/modules/Header/HeaderContactButton'
import Logo from 'components/modules/Header/Logo'
import MobileMenuButton from 'components/modules/Header/MobileMenuButton'
import Navigation from 'components/modules/Header/Navigation'
import SearchButton from 'components/modules/Header/SearchButton'
import Submenu from 'components/modules/Header/Submenu'
import MenuProvider from 'utils/Providers/Menu'

const getPortalLoginUrl = () => {
  const siteURL = process.env.NEXT_PUBLIC_SITE_URL || ''
  return siteURL.includes('localhost') || siteURL.includes('dev.')
    ? 'https://dev.portal.normet.com/login'
    : 'https://portal.normet.com/login'
}

const styles = {
  courtesyNavigation: {
    w: 'full',
    h: 8,
    bgColor: 'black',
    justifyContent: 'end',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'sm',
    alignItems: 'center',
    display: { base: 'none', lg: 'flex' },
  },
  courtesyNavigationContainer: {
    display: 'flex',
    maxW: 'desktop',
    alignItems: 'center',
    w: 'full',
    justifyContent: 'end',
    pr: 8,
  },
  header: {
    w: 'full',
    alignItems: 'center',
    minH: { sm: '49px', md: '81px' } /* 80px + 1px border */,
    backgroundColor: 'primary.white',
    borderBottom: '1px solid',
    borderColor: 'primary.grey.500',
  },
  headerContainer: {
    maxW: 'desktop',
    py: { base: 1, md: 4 },
    pl: { base: 4, md: 10 },
    pr: { base: 1, md: 6 },
  },
  menu: {
    align: 'center',
    p: 0,
    m: 0,
    justifyContent: 'space-between',
  },
  link: {
    h: 12,
    fontSize: '14px',
    alignContent: 'center',
  },
  divider: {
    h: 6,
    ml: 4,
  },
} as const

const Header: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <MenuProvider>
      <Flex {...styles.courtesyNavigation}>
        <Container {...styles.courtesyNavigationContainer}>
          <CountryButton />
          <HeaderContactButton />
        </Container>
      </Flex>
      <Flex as="header" {...styles.header}>
        <Container {...styles.headerContainer}>
          <Flex {...styles.menu}>
            <Logo />
            <Navigation />
            <Flex>
              <Link
                as={NextLink}
                variant="ghost"
                href={getPortalLoginUrl()}
                {...styles.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('components.header.sign-in')}
              </Link>
              <Divider orientation="vertical" {...styles.divider} />
              <SearchButton />
              <MobileMenuButton />
            </Flex>
          </Flex>
        </Container>
      </Flex>
      <Submenu />
    </MenuProvider>
  )
}

export default memo(Header)
