import { Container, Flex } from '@chakra-ui/react'
import React from 'react'

import ErrorBoundary from 'components/elements/ErrorBoundary'
import LoadProgressBar from 'components/elements/LoadProgressBar'
import PreviewAlert from 'components/elements/PreviewAlert'
import Footer from 'components/modules/Footer'
import Head from 'components/modules/Head'
import Header from 'components/modules/Header'
import JSONLDLogo from 'components/modules/JSON-LD/Logo'
import SEO from 'components/modules/SEO'

export interface LayoutMainProps {
  preview?: boolean
  seo?: Partial<Contentful.IComponentSeoMetaTags>
}

const TAG = 'LayoutMain'

const LayoutMain: LayoutComponent<LayoutMainProps> = ({ preview, seo = {} }, page) => (
  <>
    <LoadProgressBar />
    <PreviewAlert preview={preview} />
    <Container minH="100vh" maxW="100vw" p="0" data-testid={TAG}>
      <Flex direction="column" minH="100vh">
        <Head />
        <SEO {...seo} />
        <Header />
        <Container p={0} maxW="100vw">
          <Flex as="main" direction="column" justifyContent="center" width="full">
            <ErrorBoundary>{page}</ErrorBoundary>
          </Flex>
        </Container>
        <Footer />
      </Flex>
    </Container>
    <JSONLDLogo />
  </>
)

function getLayoutMain(props: LayoutMainProps = {}): GetLayout {
  return (page) => LayoutMain(props, page)
}

export default getLayoutMain
