import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'
import React, { FunctionComponent, ReactNode } from 'react'

import ErrorBoundary from 'components/elements/ErrorBoundary'

import '@contentful/live-preview/style.css'
import { getLocale } from 'utils/getLocale'

const ContentfulLivePreviewProvider = dynamic(
  () => import('@contentful/live-preview/react').then((module) => module.ContentfulLivePreviewProvider),
  { ssr: false },
)

const RevalidateStaticProps = dynamic(() => import('./RevalidateStaticProps'), { ssr: false })

interface LivePreviewProps {
  children: ReactNode
  /**
   * When false, it will not render the live preview provider.
   * @default false
   */
  preview: boolean
  /**
   * Live preview needs `sys.id` and `__typename` of the page. That's why we pass here `pageProps` - to extract these
   * properties from it.
   */
  pageProps: PageProps
}

const TAG = 'LivePreview'

const LivePreview: FunctionComponent<LivePreviewProps> = ({ children, preview = false, pageProps }) => {
  const { lang } = useTranslation()
  const locale = getLocale(lang)

  if (preview) {
    return (
      <ErrorBoundary>
        <ContentfulLivePreviewProvider enableInspectorMode enableLiveUpdates locale={locale}>
          <RevalidateStaticProps argument={pageProps} />
          {children}
        </ContentfulLivePreviewProvider>
      </ErrorBoundary>
    )
  }

  return <>{children}</>
}

LivePreview.displayName = TAG

export default LivePreview
