import { Button, Icon } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, { FunctionComponent } from 'react'

import ContactIcon from 'assets/contact.svg?component'
import { RoutePaths } from 'config/routes'

const styles = {
  button: {
    minH: 8,
    color: { base: 'black', lg: 'primary.white' },
    borderRadius: 0,
    px: { base: 0, lg: 3 },
    _hover: { bg: 'secondary.grey.900' },
  },
} as const

const TAG = 'HeaderContactButton'

const HeaderContactButton: FunctionComponent = () => {
  const { t } = useTranslation()
  const { push } = useRouter()

  const redirectToContactPage = () => push({ pathname: RoutePaths.contact })

  return (
    <Button
      data-testid={`${TAG}-button`}
      size="sm"
      variant="ghost"
      onClick={redirectToContactPage}
      aria-label={t('components.contactDrawer.title')}
      leftIcon={<Icon aria-hidden="true" as={ContactIcon} boxSize={5} />}
      {...styles.button}
    >
      {t(`pages.contact.header.title`)}
    </Button>
  )
}

HeaderContactButton.displayName = TAG

export default HeaderContactButton
