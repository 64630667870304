import type { SystemStyleInterpolation } from '@chakra-ui/react'

import { breakpoints } from './foundations'

export const global: Partial<SystemStyleInterpolation> = {
  body: {
    backgroundColor: 'primary.white',
    color: 'primary.black',
    fontSize: 'md',
  },
  a: {
    cursor: 'pointer',
  },
  svg: {
    fill: 'currentColor',
    overflow: 'visible',
  },
  h1: {
    fontSize: '2xl',
    lineHeight: 1.17,
  },
  h2: {
    fontSize: 'xl',
    lineHeight: 1.25,
  },
  h3: {
    fontSize: 'lg',
    lineHeight: 1.33,
  },
  h4: {
    fontSize: 'md',
    lineHeight: 1.5,
  },
  ul: {
    listStyleType: 'none !important',
  },
  blockquote: {
    fontSize: '2xl',
    lineHeight: 1.17,
    color: 'primary.red',
    fontWeight: 700,
    position: 'relative',
    paddingBottom: 10,
    paddingTop: 6,
    marginTop: '64px !important',
    marginBottom: '64px !important',
    [`@media screen and (min-width: ${breakpoints['lg']})`]: {
      _before: {
        content: '"\\”"',
        position: 'absolute',
        top: 0,
        left: '-60px',
        fontSize: '80px',
        lineHeight: 0.9,
      },
    },
    _after: {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      display: 'block',
      w: { base: '100%', sm: 'side-block' },
      h: 2,
      bg: 'primary.red',
    },
  },
  '*': {
    scrollbarWidth: 'thin',
    scrollbarColor: '#ccc',
  },
  '*::-webkit-scrollbar': {
    width: '0.375rem',
    height: '0.375rem',
  },
  'body::-webkit-scrollbar': {
    width: '0.75rem',
    height: '0.75rem',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccc',
    border: 'none',
    borderRadius: '1rem',
    transition: 'background-color 0.2s ease',
  },
  '*::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#999',
  },
  '.grecaptcha-badge ': {
    visibility: 'hidden',
  },
  '@font-face': [
    {
      fontFamily: `'Proxima Nova'`,
      fontStyle: 'normal',
      fontWeight: 400,
      fontDisplay: 'swap',
      src: [
        `local('Proxima Nova Regular')`,
        `local('ProximaNova-Regular')`,
        `local('Proxima Nova')`,
        `url('/fonts/ProximaNovaRegular.woff2') format('woff2')`,
        `url('/fonts/ProximaNovaRegular.woff') format('woff')`,
        `url('/fonts/ProximaNovaRegular.otf') format('opentype')`,
      ].join(),
    },
    {
      fontFamily: `'Proxima Nova'`,
      fontStyle: 'normal',
      fontWeight: 700,
      fontDisplay: 'swap',
      src: [
        `local('Proxima Nova Bold')`,
        `local('ProximaNova-Bold')`,
        `local('Proxima Nova')`,
        `url('/fonts/ProximaNovaBold.woff2') format('woff2')`,
        `url('/fonts/ProximaNovaBold.woff') format('woff')`,
        `url('/fonts/ProximaNovaBold.otf') format('opentype')`,
      ].join(),
    },
    // Generated with `pixel-point/fontpie`
    {
      fontFamily: 'Adjusted Arial Fallback',
      src: 'local(Arial)',
      fontStyle: 'normal',
      fontWeight: 400,
      sizeAdjust: '99%',
      ascentOverride: '99%',
      descentOverride: '26%',
      lineGapOverride: '0%',
    },
    {
      fontFamily: 'Adjusted Arial Fallback',
      src: "local('Arial Bold')",
      fontStyle: 'normal',
      fontWeight: 700,
      ascentOverride: '97.08%',
      descentOverride: '31.44%',
      lineGapOverride: '0.00%',
      sizeAdjust: '94.77%',
    },
  ],
  /* Cookiebot */
  'a#CybotCookiebotDialogPoweredbyCybot': {
    display: 'none',
  },
  'div#CybotCookiebotDialogPoweredByText': {
    display: 'none',
  },
} as const
