export const RECAPTCHA_API_URL = '/api/recaptcha/' as const

import { UseFormSetError } from 'react-hook-form/dist/types'

import { ContactFormInputs } from 'config/contact'
import { NewsletterFormInputs } from 'config/newsletter'

const formSubmitHandler = (
  token: string | undefined,
  data: ContactFormInputs | NewsletterFormInputs | Record<string, string>,
  errorMessage: string,
  setError: UseFormSetError<ContactFormInputs> | UseFormSetError<NewsletterFormInputs>,
) => {
  return new Promise((resolve, reject) => {
    if (!token || !process.env.ELOQUA_FORM_URL) {
      setError('apiError', { message: errorMessage })
      return reject(errorMessage)
    }

    const formData = new FormData()

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value.toString())
    })

    const formSubmissionRequest = new XMLHttpRequest()

    // Open the connection and set the method to POST
    formSubmissionRequest.open('POST', process.env.ELOQUA_FORM_URL, true)

    // Set the onload event handler
    formSubmissionRequest.onload = function () {
      // If response is contain url to submit confirmation we return true
      if (
        formSubmissionRequest.status === 200 &&
        formSubmissionRequest.responseText.includes('DefaultFormSubmitConfirmation')
      ) {
        resolve(true)
      } else {
        // Rejected promise should set form state to isSubmittedSuccess: false, but it doesn't, this is why we need to set error manually to form
        setError('apiError', { message: errorMessage })
        reject(errorMessage)
      }
    }

    fetch(RECAPTCHA_API_URL, {
      method: 'post',
      body: JSON.stringify({ token }),
    })
      .then((response) => {
        if (response.status === 200) {
          formSubmissionRequest.send(formData)
          resolve(true)
        } else {
          setError('apiError', { message: errorMessage })
          reject(errorMessage)
        }
      })
      .catch(() => {
        setError('apiError', { message: errorMessage })
        reject(errorMessage)
      })
  })
}

export default formSubmitHandler
