/**
 * When Contentful returns an array of references, it can contain null values
 * when it can't find the entry with specific id.
 * This function removes all falsy values from the array.
 */
function removeEmptyValues<ARRAY_ITEM>(
  array: ReadonlyArray<ARRAY_ITEM | undefined | null> | undefined | null,
): NonNullable<ARRAY_ITEM>[] {
  return array?.filter((item): item is NonNullable<ARRAY_ITEM> => !!item) || []
}

export default removeEmptyValues
