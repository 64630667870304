import { Link, Stack } from '@chakra-ui/react'
import NextLink from 'next/link'
import React, { FunctionComponent, useCallback } from 'react'

import { MenuTypeProps } from 'components/modules/Header/Navigation'
import MenuLineItemButton from 'components/modules/Header/Navigation/MenuLineItemButton'
import LandingPageCategoryContent from 'components/modules/Header/Submenu/LandingPageCategory/LandingPageCategoryContent'
import SlideDrawer from 'components/modules/Header/Submenu/SlideDrawer'
import { useMenu } from 'utils/Providers/Menu/hooks'
import removeEmptyValues from 'utils/removeEmptyValues'

/**
 * Used for E2E tests.
 */
const TAG = 'LandingPageCategoryMobile'

const LandingPageCategoryMobile: FunctionComponent<
  Contentful.Entry<Pick<MenuTypeProps, 'heading' | 'slug' | 'megaMenu'>>
> = ({ heading, slug, megaMenu }) => {
  const { childMenuKey, onMobileSubmenuChange, closeSubmenu } = useMenu()

  const handleGoBack = useCallback(() => {
    onMobileSubmenuChange('')
  }, [onMobileSubmenuChange])

  return (
    <Stack data-testid={TAG} px={4} mt={4} spacing={0} display={{ base: 'block', lg: 'none' }}>
      <Link fontSize="lg" color="primary.red" fontWeight="bold" mb={4} as={NextLink} href={slug as string}>
        {heading}
      </Link>

      <Stack direction="column">
        {removeEmptyValues(megaMenu?.items).map((item) => (
          <MenuLineItemButton key={item.sys.id} {...item} isChildSubMenu />
        ))}
      </Stack>
      <SlideDrawer goBack={handleGoBack} onClose={closeSubmenu} in={Boolean(childMenuKey)}>
        <Stack px={4}>
          {removeEmptyValues(megaMenu?.items).map(
            (item) => childMenuKey === item.heading && <LandingPageCategoryContent key={item.heading} {...item} />,
          )}
        </Stack>
      </SlideDrawer>
    </Stack>
  )
}

LandingPageCategoryMobile.displayName = TAG

export default LandingPageCategoryMobile
