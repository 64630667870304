import { Button } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import NextLink from 'next/link'
import React, { FunctionComponent } from 'react'

import { MenuTypePropsBase } from 'components/modules/Header/Navigation/'
import useResolveDynamicPage from 'utils/Providers/DynamicPage/hooks/useResolveDynamicPage'

interface MenuLineItemLinkProps
  extends Contentful.Entry<Pick<MenuTypePropsBase, 'heading' | 'title' | 'slug' | 'targetUrl' | 'targetReference'>> {
  sys: {
    id: string
  }
}

/**
 * Used for E2E tests.
 */
const TAG = 'MenuLineItemLink'

/**
 * Used for select link type
 */
const MenuLineItemLink: FunctionComponent<MenuLineItemLinkProps> = ({
  heading,
  targetUrl,
  title,
  targetReference,
  sys,
}) => {
  const resolveLink = useResolveDynamicPage('')
  const link: string = targetUrl || resolveLink(sys.id) || resolveLink(targetReference?.sys?.id)
  const children: string = heading || title || targetReference?.heading || capitalize(link)

  return (
    <Button data-testid={TAG} variant="menu" as={NextLink} href={link} fontSize="1rem">
      {children}
    </Button>
  )
}

MenuLineItemLink.displayName = TAG

export default MenuLineItemLink
