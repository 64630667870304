import { Box, Container, Flex, Text } from '@chakra-ui/react'
import { FunctionComponent, memo } from 'react'

import BottomRow from 'components/modules/Footer/BottomRow'
import ContactUs from 'components/modules/Footer/ContactUs'
import FooterMenu from 'components/modules/Footer/FooterMenu'
import SocialMedia from 'components/modules/Footer/SocialMedia'
import Subscribe from 'components/modules/Footer/Subscribe'
import Logo from 'components/modules/Header/Logo'

const Footer: FunctionComponent = () => (
  <Box as="footer" bgColor="secondary.grey.100" w="full">
    <Container maxW="desktop" p={10}>
      <Container maxW="desktop-content" p={0}>
        <Logo />

        <Flex direction={{ base: 'column', md: 'row' }} align="start" my={20} w="full" gap={{ base: 20, md: '140px' }}>
          <Box as="aside" maxW={{ md: 'side-block' }}>
            <Subscribe />
            <ContactUs />
            <SocialMedia />
          </Box>
          <FooterMenu />
        </Flex>

        <BottomRow />
        {process.env.BUILD_ID && (
          <Text mt="4" fontSize="xs" color="gray.300">
            Ver. {process.env.BUILD_ID}
          </Text>
        )}
      </Container>
    </Container>
  </Box>
)

export default memo(Footer)
