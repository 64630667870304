import { InputGroup, InputLeftElement, Icon, Input, InputRightElement, Button } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { ComponentProps, FunctionComponent, MutableRefObject } from 'react'
import { UseFormRegister } from 'react-hook-form'

import CloseIcon from 'assets/close.svg?component'
import SearchIcon from 'assets/search.svg?component'

interface SearchInputProps extends ComponentProps<typeof Input> {
  register: UseFormRegister<Search.SearchForm>
  clearInput: VoidFunction
  isSubmitting: boolean
  inputRef?: React.Ref<HTMLInputElement | null>
}

/**
 * Used for E2E tests.
 */
const TAG = 'SearchInput'

/**
 * We want to limit the length of the search input to avoid performance issues.
 */
const SEARCH_INPUT_MAX_LENGTH = 100

const styles = {
  left: {
    pointerEvents: 'none',
    h: 'full',
    color: 'secondary.grey.900',
  },
  input: {
    sx: {
      // Hide clear button when input is empty.
      '&:placeholder-shown + div': { display: 'none' },
      // Hide autofill background.
      '&:where(::autofill, :-webkit-autofill)': {
        '&, &:focus, &:active': {
          boxShadow: '0 0 0 20rem var(--nm-colors-white) inset!important;',
        },
      },
    },
  },
  right: {
    h: 'full',
  },
} as const

const SearchInput: FunctionComponent<SearchInputProps> = ({
  register,
  clearInput,
  isSubmitting,
  inputRef,
  boxSize = '1.5rem',
  ...props
}) => {
  const { t } = useTranslation()
  const { ref, ...rest } = register('query', { required: true })

  return (
    <InputGroup>
      <InputLeftElement {...styles.left}>
        <Icon aria-hidden="true" as={SearchIcon} boxSize={boxSize} />
      </InputLeftElement>
      <Input
        {...styles.input}
        id={`${TAG}-input`}
        data-testid={`${TAG}-input`}
        type="text"
        enterKeyHint="search"
        aria-label={t('components.header.searchInput.placeholder')}
        placeholder={t('components.header.searchInput.placeholder')}
        {...rest}
        readOnly={isSubmitting}
        maxLength={SEARCH_INPUT_MAX_LENGTH}
        role="searchbox"
        {...props}
        ref={(e) => {
          ref(e)
          if (inputRef) {
            (inputRef as MutableRefObject<HTMLInputElement | null>).current = e
          }
        }}
      />
      <InputRightElement {...styles.right}>
        <Button
          variant="ghost"
          color="primary.red"
          data-testid={`${TAG}-button`}
          aria-label={t('components.header.searchInput.clear')}
          onClick={clearInput}
        >
          <Icon as={CloseIcon} boxSize={3} />
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}

SearchInput.displayName = TAG

export default SearchInput
