import { useDisclosure } from '@chakra-ui/react'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'
import React, { FunctionComponent, ReactNode, useCallback, useContext, useEffect, useState } from 'react'

import useMemoCompare from 'hooks/useMemoCompare'
import { MenuContext, MenuContextValue } from 'utils/Providers/Menu/context'

interface MenuProviderProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */

const TAG = 'MenuProvider'

/**
 * We don't want to update MenuContext value when route doesn't change.
 * but only event was fired on route change.
 */
const CloseMenuOnRouteChange: FunctionComponent = () => {
  const router = useRouter()
  const { closeNavigation, isOpen, isOpenMobileButton } = useContext(MenuContext)

  useEffect(() => {
    if (isOpenMobileButton || isOpen) {
      router.events.on('routeChangeStart', closeNavigation)
      return () => {
        router.events.off('routeChangeStart', closeNavigation)
      }
    }
  }, [closeNavigation, isOpen, isOpenMobileButton, router.events])

  return null
}

const MenuProvider: FunctionComponent<MenuProviderProps> = ({ children }) => {
  const mobileMenuButton = useDisclosure()
  const { isOpen, onToggle, onClose } = useDisclosure()

  const [parentMenuKey, setParentMenuKey] = useState('')

  const [childMenuKey, setChildMenuKey] = useState('')
  /**
   * When user change page, close collapsed submenu
   */

  const closeSubmenu = useCallback(() => {
    onClose()
    setParentMenuKey('')
    setChildMenuKey('')
  }, [onClose])

  const closeNavigation = useCallback(() => {
    mobileMenuButton.onClose()
    closeSubmenu()
  }, [closeSubmenu, mobileMenuButton])

  const onDesktopSubmenuChange = useCallback(
    (type: string) => {
      if (isOpen && parentMenuKey === type) {
        onToggle()
        return
      }
      if (!isOpen) {
        onToggle()
      }
      setParentMenuKey(type)
    },
    [isOpen, parentMenuKey, onToggle],
  )

  const onMobileSubmenuChange = useCallback((type: string) => {
    setChildMenuKey(type)
  }, [])

  const memoizedValue = useMemoCompare<MenuContextValue>(
    () => ({
      isOpen,
      isOpenMobileButton: mobileMenuButton.isOpen,
      onMobileButtonOpen: mobileMenuButton.onOpen,
      parentMenuKey,
      childMenuKey,
      onDesktopSubmenuChange,
      onMobileSubmenuChange,
      closeSubmenu,
      closeNavigation,
    }),
    isEqual,
  )

  return (
    <MenuContext.Provider value={memoizedValue}>
      {children}
      <CloseMenuOnRouteChange />
    </MenuContext.Provider>
  )
}

MenuProvider.displayName = TAG

export default MenuProvider
