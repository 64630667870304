import Head from 'next/head'
import React, { FunctionComponent } from 'react'

interface SEOProps extends Partial<Contentful.IComponentSeoMetaTagsFields> {}

const TAG = 'Head'

/**
 * SEO component which will add all required meta tags.
 *
 * Note: each `meta` tag has attribute `key`. DO NOT REMOVE `key` ATTRIBUTE!.
 * This attribute will make sure the tag is only rendered once
 *
 * @example
 * SEO using layout props:
 * ```tsx
 * // pages/index.tsx
 * import type { GetStaticProps } from 'next'
 * import React from 'react'
 *
 * const Page: NextPageWithLayout = () => {
 *   return <span>Page</span>
 * }
 *
 * // This way will work out also with `getServerSideProps`
 * export const getStaticProps: GetStaticProps<AppPageLayoutProps> = () => {
 *   const pageContent = await getHomePageData()
 *   return {
 *     props: {
 *       layout: {
 *         props: {
 *           seo: pageContent.seoMetaTags
 *         },
 *       },
 *     },
 *   }
 * }
 *
 * export default Page
 * ```
 */
const SEO: FunctionComponent<SEOProps> = ({ doNotFollow, doNotIndex, title, description, image, keywords }) => {
  const robots = [doNotIndex && 'noindex', doNotFollow && 'nofollow'].filter(Boolean).join(',')

  return (
    <Head>
      {title ? (
        <>
          <title key="title">{title}</title>
          <meta key="twitter:title" property="twitter:title" content={title} />
          <meta key="og:title" property="og:title" content={title} />
        </>
      ) : null}

      {description ? (
        <>
          <meta key="description" name="description" content={description} />
          <meta key="og:description" property="og:description" content={description} />
          <meta key="twitter:description" property="twitter:description" content={description} />
        </>
      ) : null}

      {image && (
        <>
          <meta key="og:image:type" property="og:image:type" content={image.contentType} />
          <meta key="og:image:width" property="og:image:width" content={`${image.width}`} />
          <meta key="og:image:height" property="og:image:height" content={`${image.height}`} />
          <meta key="og:image:alt" property="og:image:alt" content={image.title} />
          <meta key="og:image" property="og:image" content={image.url} />

          <meta key="twitter:image" property="twitter:image" content={image.url} />
          <meta key="twitter:card" property="twitter:card" content="summary_large_image" />
        </>
      )}

      {robots ? <meta key="robots" name="robots" content={robots} /> : null}

      {keywords?.length ? <meta key="keywords" name="keywords" content={keywords.join(',')} /> : null}
    </Head>
  )
}

SEO.displayName = TAG

export default SEO
