import type { ComponentStyleConfig } from '@chakra-ui/react'

export const Drawer: ComponentStyleConfig = {
  parts: ['dialog', 'body', 'header'],
  sizes: {
    lg: { dialog: { maxW: 'drawer-lg' } },
  },
  baseStyle: {
    dialog: {
      boxShadow: '-4px 4px 8px rgba(0, 0, 0, 0.15)',
    },

    closeButton: {
      color: 'primary.red',
      top: '38px',
      right: 4,
    },
    header: {
      pt: 10,
      pb: 4,
      px: { base: 4, lg: 10 },
    },
    body: {
      py: [1, 2],
      px: { base: 4, lg: 10 },
    },
  },
}
