import React, { FunctionComponent } from 'react'

import { Organization } from 'components/modules/JSON-LD/config'

const TAG = 'JSON-LD.Logo'

const CONTENT_LOGO = { __html: JSON.stringify(Organization) } as const

/**
 * Structured data for logo.
 * @link https://developers.google.com/search/docs/appearance/structured-data/logo
 */
const JSONLDLogo: FunctionComponent = () => (
  <script id="json-ld-logo" type="application/ld+json" dangerouslySetInnerHTML={CONTENT_LOGO} />
)

JSONLDLogo.displayName = TAG

export default JSONLDLogo
