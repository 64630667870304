import { chakra, ImageProps as ChakraImageProps, propNames } from '@chakra-ui/react'
import NextImage, { ImageProps as NextImageProps } from 'next/image'
import { CSSProperties } from 'react'

import { BreakpointKeys, breakpoints } from 'theme/foundations/breakpoints'

type Width = NonNullable<ChakraImageProps['width']>
type Height = NonNullable<ChakraImageProps['height']>

type OnlyResponsiveWidth = Exclude<Width, string | number>
type OnlyResponsiveHeight = Exclude<Height, string | number>

export type ChakraNextImageProps = Omit<NextImageProps & ChakraImageProps, 'width' | 'height'> & {
  width: Width
  height: Height
  focalPoint?: Pick<Contentful.IComponentMediaWrapperFields, 'focalPoint'>
  originalWidth?: number
  originalHeight?: number
}
const invalidPropNames = propNames.filter((prop) => !['width', 'height'].includes(prop))

export const ChakraNextImage = chakra(NextImage, {
  baseStyle: {
    objectFit: 'cover',
  },
  label: 'image',
  shouldForwardProp: (property: string) => !invalidPropNames.includes(property),
})

export function isResponsive(val: Width | Height): val is OnlyResponsiveWidth | OnlyResponsiveHeight {
  return !!val && typeof val === 'object'
}

export function getResponsive(val: Width | Height) {
  return isResponsive(val) ? val : []
}

/**
 * We want to display background color while loading the image to "preserve" the space of the image.
 * It will return `backgroundColor` only when `objectFit` is not defined or its value is `cover`, because
 * we don't want to have background image when `objectFit` is set to e.g. `contain`
 */
export function getBackgroundColor(style: Readonly<CSSProperties>, objectFit: string | undefined): string {
  const styleOF = style['objectFit'] || objectFit
  return !styleOF || styleOF === 'cover' ? 'greyCCC' : 'transparent'
}

/**
 * Function that calculates `sizes` property based on `width` when it is responsive.
 * Thanks to that, browser can display the image in the right size before it is loaded.
 */
export function getSizes(width: Width): string | undefined {
  if (isResponsive(width) && 'base' in width) {
    const sizes: string[] = []

    // We need to sort breakpoints to make sure that the smallest one is first.
    const orderedWidth = Object.keys(width).sort((a, b) => {
      if (a === 'base') {
        return 1
      }
      if (b === 'base') {
        return -1
      }

      return (
        +(breakpoints[b as BreakpointKeys] || '').split('px')[0] -
        +(breakpoints[a as BreakpointKeys] || '').split('px')[0]
      )
    })

    orderedWidth.map((breakpoint) => {
      if (breakpoint === 'base') {
        // We will add `base` width at the end of the array.
        return
      }
      sizes.push(`(min-width: ${breakpoints[breakpoint as BreakpointKeys]}) ${width[breakpoint as BreakpointKeys]}px`)
    })

    sizes.push(`${width['base' as keyof typeof width]}px`)
    return sizes.join(', ')
  }

  return void 0
}
