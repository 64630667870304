import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Heading,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent, useRef } from 'react'

import SearchDrawerForm from 'components/modules/Header/SearchDrawerForm'

/**
 * Used for E2E tests.
 */
const TAG = 'SearchDrawer'

const SearchDrawer: FunctionComponent<Omit<DrawerProps, 'children'>> = ({ ...props }) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement | null>(null)

  return (
    <Drawer
      id={`${TAG}-dialog`}
      data-testid={`${TAG}-dialog`}
      placement="right"
      size="md"
      initialFocusRef={inputRef}
      {...props}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading as="h2" size="md">
            {t('components.header.searchButton.title')}
          </Heading>
        </DrawerHeader>

        <DrawerBody>
          <SearchDrawerForm onClose={props.onClose} inputRef={inputRef} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

SearchDrawer.displayName = TAG

export default SearchDrawer
