import { useBreakpointValue } from '@chakra-ui/react'
import { CSSProperties, FunctionComponent, useMemo } from 'react'

import {
  ChakraNextImageProps,
  getResponsive,
  getBackgroundColor,
  ChakraNextImage,
  isResponsive,
  getSizes,
} from 'components/elements/Image/Image.config'

/**
 * Chakra & Next.js image component
 *
 * @example
 * ```ts
 * <Image
 *   src={image.url}
 *   alt={image.title}
 *   width="668"
 *   height={510}
 * />
 * ```
 */
const Image: FunctionComponent<ChakraNextImageProps> = ({
  width,
  height,
  style = {},
  objectFit,
  src,
  alt,
  focalPoint,
  originalWidth,
  originalHeight,
  ...props
}) => {
  const responsiveWidth = useBreakpointValue(getResponsive(width))
  const responsiveHeight = useBreakpointValue(getResponsive(height))

  const imageWidth = isResponsive(width) ? responsiveWidth ?? width : width
  const imageHeight = isResponsive(height) ? responsiveHeight ?? height : height
  const imageStyle = useMemo<CSSProperties>(
    () =>
      ({ width: +imageWidth, height: +imageHeight, aspectRatio: `${+imageWidth}/${+imageHeight}`, ...style } as const),
    [imageHeight, imageWidth, style],
  )

  const imageSrc = src || '/images/fallback-image.png'
  const imageAlt = alt ?? ''

  const objectPosition = useMemo(() => {
    if (!focalPoint || !originalWidth || !originalHeight) {
      return
    }

    const POINT_X = focalPoint?.focalPoint?.x as number
    const POINT_Y = focalPoint?.focalPoint?.y as number

    const placement_X = POINT_X / originalWidth
    const placement_Y = POINT_Y / originalHeight

    const pos_X = placement_X < 0.25 ? 'left' : placement_X > 0.75 ? 'right' : `${placement_X * 100}%`
    const pos_Y = placement_Y < 0.33 ? 'top' : placement_Y > 0.66 ? 'bottom' : 'center'

    return `${pos_X} ${pos_Y}`
  }, [focalPoint, originalHeight, originalWidth])

  return (
    <ChakraNextImage
      backgroundColor={getBackgroundColor(style, objectFit)}
      {...props}
      fetchpriority={props.priority ? 'high' : 'auto'}
      loading={props.loading || (props.priority ? 'eager' : 'lazy')}
      priority={props.priority ?? props.loading === 'eager'}
      sizes={getSizes(width)}
      src={imageSrc}
      key={imageSrc} // Force remount when src changes
      alt={imageAlt}
      placeholder={props.blurDataURL ? 'blur' : undefined}
      objectFit={focalPoint?.focalPoint ? 'cover' : objectFit}
      style={imageStyle}
      width={imageWidth}
      height={imageHeight}
      objectPosition={objectPosition}
    />
  )
}

export default Image
