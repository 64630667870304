export const Checkbox = {
  defaultProps: {
    variant: 'primary',
  },
  parts: ['control', 'label'],
  baseStyle: {
    control: {
      alignSelf: 'start',
      borderRadius: '2',
      borderWidth: 1,
      borderColor: 'greyCCC',
      height: '6',
      width: '6',
      fontSize: '10px',
      _invalid: {
        borderColor: 'red',
        borderWidth: 2,
      },
    },
  },
  sizes: {
    sm: {
      label: { fontSize: 'sm' },
    },
  },
  variants: {
    primary: {
      control: {
        backgroundColor: 'white',
        _checked: {
          borderColor: 'primary.black',
          background: 'primary.black',
          _hover: {
            background: 'primary.black',
            borderColor: 'primary.black',
          },
        },
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
}
