const isValidUrl = (targetUrl: string | undefined): boolean => {
  if (!targetUrl) {
    return false
  }
  try {
    const url = new URL(targetUrl)
    return url?.hostname?.includes('.') // to exclude targetUrl like https://something
  } catch (e) {
    return false
  }
}

export default isValidUrl
