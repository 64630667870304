import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { DEFAULT_LANGUAGE } from 'config/i18n.config'
import { RoutePathsKeys, ROUTES, RoutePaths, RoutePathsValues } from 'config/routes'

/**
 * If there is no route for `route` argument, it will return the home route.
 */
interface GetRoute {
  (route: RoutePathsKeys | RoutePathsValues, locale?: string): string
  (route: string, locale?: string): string
}

const getRouteKeyByValue = <ROUTES extends Record<string, string>>(
  routes: ROUTES,
  value: string,
): keyof ROUTES | undefined =>
  Object.keys(routes).find((k) => routes[k] === value || routes[k] === `${value}/`) as keyof ROUTES | undefined

/**
 * Hook to allow use of localized routes.
 *
 * @example
 * ```ts
 * const YourComponent: FunctionComponent = () => {
 *   const getRoute = useRoutePaths()
 *
 *   return (
 *     <Link href={getRoute('home')} aria-label="Home">
 *       Link to home
 *     </Link>
 *   )
 * }
 * ```
 */
function useRoutePaths(): GetRoute {
  const { locale: currentLocale = DEFAULT_LANGUAGE } = useRouter()

  const getRoute = useCallback<GetRoute>(
    (route, locale = currentLocale) => {
      const routes = ROUTES[locale as keyof typeof ROUTES] || ROUTES[DEFAULT_LANGUAGE]

      // Route by key
      let path = routes[route as RoutePathsKeys] || RoutePaths[route as RoutePathsKeys]
      if (!path) {
        // Route by value
        const keyByValue = getRouteKeyByValue(routes, route) || getRouteKeyByValue(RoutePaths, route)
        path = keyByValue ? routes[keyByValue] || RoutePaths[keyByValue] || RoutePaths.home : RoutePaths.home
      }
      return `/${locale}${path}`
    },
    [currentLocale],
  )

  return getRoute
}

export default useRoutePaths
