import { default as NextHead } from 'next/head'
import React, { FunctionComponent, useContext } from 'react'

import CanonicalURL from 'components/modules/Head/CanonicalURL'
import SEO from 'components/modules/SEO'
import { GlobalPropsContext } from 'utils/Providers/GlobalProps/context'

/**
 * Used for E2E tests.
 */
const TAG = 'Head'

const Head: FunctionComponent = () => {
  const { defaultSeoMetaTags, siteTitle } = useContext(GlobalPropsContext)

  return (
    <>
      <SEO {...defaultSeoMetaTags} />
      <CanonicalURL />
      <NextHead>
        <meta name="og:site_name" content={siteTitle} />
        <meta name="apple-mobile-web-app-title" content={siteTitle} />
        <meta name="application-name" content={siteTitle} />
        <link
          title={siteTitle}
          rel="search"
          type="application/opensearchdescription+xml"
          href="/open-search-description.xml"
        />
        <meta property="og:type" content="website" />
      </NextHead>
    </>
  )
}

Head.displayName = TAG

export default Head
