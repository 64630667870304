import React, { FunctionComponent } from 'react'

import { MenuTypeProps } from 'components/modules/Header/Navigation/'
import MenuLineItemButton from 'components/modules/Header/Navigation/MenuLineItemButton'
import MenuLineItemLink from 'components/modules/Header/Navigation/MenuLineItemLink'

/**
 * Used for E2E tests.
 */
const TAG = 'MenuContentType'

/**
 * Used for select link type
 */

const MenuContentType: FunctionComponent<MenuTypeProps> = (props) => {
  switch (props.contentType) {
    case 'LandingPageSegment':
    case 'LandingPageCategory':
      return <MenuLineItemButton {...props} />
    case 'PageArticle':
    case 'ComponentNavigationItem':
      return <MenuLineItemLink {...props} />
    default:
      return <></>
  }
}

MenuContentType.displayName = TAG

export default MenuContentType
