import { SlideProps, Box, Slide, Button, Icon, CloseButton } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent, MouseEventHandler } from 'react'

import ChevronIcon from 'assets/chevron.svg?component'

/**
 * Used for E2E tests.
 */
const TAG = 'SlideDrawer'

type Props = SlideProps & {
  onClose: MouseEventHandler<HTMLButtonElement> | undefined
  goBack: () => void
}
const SlideDrawer: FunctionComponent<Props> = ({ children, goBack, onClose, ...props }) => {
  const { t } = useTranslation()

  return (
    <Slide direction="right" style={{ zIndex: 10 }} {...props}>
      <Box h="full" px={2} py={4} bg="white" shadow="md">
        <Button
          data-testid={`${TAG}-button`}
          variant="ghost"
          aria-haspopup="dialog"
          aria-controls={`chakra-slide-${TAG}`}
          aria-label={t('components.header.mobileButton.button')}
          onClick={goBack}
        >
          <Icon boxSize={3} transform="rotate(180deg)" color="primary.black" as={ChevronIcon} />
        </Button>
        <CloseButton position="absolute" onClick={onClose} color="primary.black" top={5} right={4} />
        {children}
      </Box>
    </Slide>
  )
}

SlideDrawer.displayName = TAG

export default SlideDrawer
