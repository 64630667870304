import hasProperty from 'utils/hasProperty'
import isValidUrl from 'utils/isValidUrl'
import normalizeUrl from 'utils/normalizeUrl'
import type useResolveDynamicPage from 'utils/Providers/DynamicPage/hooks/useResolveDynamicPage'

interface GetHrefOptions {
  targetUrl?: string
  targetReference?: Contentful.Entry<object> | undefined
  sysId?: string
  slug?: string
  resolveLink: ReturnType<typeof useResolveDynamicPage>
}

function getTargetId(targetReference?: Contentful.Entry<object> | undefined): string | undefined {
  if (hasProperty(targetReference?.sys, 'id') && typeof targetReference?.sys?.id === 'string') {
    return targetReference.sys.id
  }
}

function getRelativeURL(url: unknown): string | undefined {
  if (typeof url === 'string' && url.startsWith('/')) {
    return normalizeUrl(`/${url}/`)
  }
}

/**
 * Function to resolve the href of a navigation item.
 */
function getHref({ targetUrl, targetReference, sysId, slug, resolveLink }: GetHrefOptions): string | undefined {
  // If the targetUrl is a full URL - e.g. https://www.google.com - return it as is
  if (isValidUrl(targetUrl)) {
    return targetUrl
  }

  // If the target is a reference to a Contentful entry, try to resolve it
  const id = getTargetId(targetReference) || sysId
  const resolved = resolveLink(id)
  if (resolved) {
    return resolved
  }

  // If the target is relative, return it
  return getRelativeURL(slug || targetReference?.slug || targetUrl)
}

export default getHref
