import { FunctionComponent } from 'react'

import { MenuTypeProps } from 'components/modules/Header/Navigation'
import LandingPageCategory from 'components/modules/Header/Submenu/LandingPageCategory'
import LandingPageCategoryMobile from 'components/modules/Header/Submenu/LandingPageCategoryMobile'
import LandingPageSegment from 'components/modules/Header/Submenu/LandingPageSegment'

const SubmenuContentType: FunctionComponent<MenuTypeProps> = (props) => {
  switch (props.contentType) {
    case 'LandingPageSegment':
      return <LandingPageSegment {...props} />
    case 'LandingPageCategory':
      return (
        <>
          <LandingPageCategory {...props} />
          <LandingPageCategoryMobile {...props} />
        </>
      )
    default:
      return <></>
  }
}

export default SubmenuContentType
