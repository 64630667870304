export const Alert = {
  variants: {
    success: {
      container: {
        color: 'primary.white',
        bg: 'secondary.green',
      },
    },
    warning: {
      container: {
        color: 'primary.black',
        bg: 'secondary.beige.600',
      },
    },
  },
}
