export const Textarea = {
  sizes: {
    xl: {
      minHeight: 168,
    },
  },
  variants: {
    outline: {
      rounded: 'none',
      borderColor: 'greyCCC',
      p: 4,
      _focus: {
        borderColor: 'grey999',
        boxShadow: 'none',
      },
    },
  },
}
