import { Flex, Link, Text } from '@chakra-ui/react'
import Trans from 'next-translate/Trans'
import React, { FunctionComponent } from 'react'

interface PreviewAlertProps {
  /**
   * @default false
   */
  preview: boolean | undefined
}

/**
 * Used for E2E tests.
 */
const TAG = 'PreviewAlert'

const COMPONENTS = {
  // Mustn't it be link from Next.js, because we don't want to prefetch that href.
  Link: <Link href="/api/endpreview/" />,
}

const PreviewAlert: FunctionComponent<PreviewAlertProps> = ({ preview = false }) =>
  preview ? (
    <Flex
      data-testid={TAG}
      backgroundColor="black"
      minHeight="10"
      justifyContent="center"
      alignItems="center"
      width="full"
    >
      <Text color="white" textAlign="center">
        <Trans i18nKey="components.previewAlert.title" components={COMPONENTS} />
      </Text>
    </Flex>
  ) : null

PreviewAlert.displayName = TAG

export default PreviewAlert
