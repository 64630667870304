import { Stack } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, ReactNode } from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'

import { ContactFormDefaultValues, EloquaFormInputs } from 'config/eloqua'
import useGoogleReCaptcha from 'hooks/useGoogleReCaptcha'
import formSubmitHandler from 'utils/formSubmitHandler'

interface ContactFormProviderProps {
  children: ReactNode
}

export type ContactFormInputs = EloquaFormInputs & {
  topic: ReadonlyArray<{ label: string; value: string | number }>
  subject: string
  message: string
  name: string
  emailAddress: string
  busPhone: string
  company: string
  exampleRequired: string
  country: string
  consent: boolean
}

/**
 * Used for E2E tests.
 */

const TAG = 'ContactFormProvider'

const ContactFormProvider: FunctionComponent<ContactFormProviderProps> = ({ children }) => {
  const form = useForm<ContactFormInputs>({
    defaultValues: ContactFormDefaultValues,
    reValidateMode: 'onChange',
    mode: 'onBlur',
  })
  const { t } = useTranslation()
  const { handleSubmit } = form

  const { executeReCaptcha } = useGoogleReCaptcha()

  const onSubmit: SubmitHandler<ContactFormInputs> = async (data) => {
    const reCaptchaResponseToken = await executeReCaptcha('LoadReCaptchaOnStart')
    return formSubmitHandler(
      reCaptchaResponseToken,
      {
        ...data,
        // Current page URL
        siteURL: window.location.href,
      },
      t('components.contactForm.errors.error-form-api'),
      form.setError,
    )
  }
  return (
    <FormProvider {...form}>
      <Stack as="form" name="contactForm" onSubmit={handleSubmit(onSubmit)} spacing={6}>
        {children}
      </Stack>
    </FormProvider>
  )
}

ContactFormProvider.displayName = TAG

export default ContactFormProvider
