import { LinkProps } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import React, { FunctionComponent, ReactNode } from 'react'

import ChakraNextLink from 'components/elements/Link'
import getHref from 'components/elements/NavigationItem/utils/getHref'
import useResolveDynamicPage from 'utils/Providers/DynamicPage/hooks/useResolveDynamicPage'

export type NavigationItemProps = Omit<
  Contentful.IBlockLinkListFields & Contentful.IComponentNavigationItemFields & LinkProps,
  'as' | 'heading'
> & {
  __typename?: string
  contentType?: string
  heading?: string
  slug?: string
  sys?: { id?: string }
  wrapper?: FunctionComponent<{ children: ReactNode }>
}

/**
 * Used for E2E tests.
 */
const TAG = 'NavigationItem'

const NavigationItem: FunctionComponent<NavigationItemProps> = ({
  title,
  heading,
  internalName,
  slug,
  targetUrl,
  targetReference,
  sys,
  contentType,
  __typename,
  wrapper: Wrapper,
  ...props
}) => {
  const resolveLink = useResolveDynamicPage('')

  const href: string | undefined = getHref({
    targetUrl,
    targetReference,
    sysId: sys?.id,
    slug,
    resolveLink,
  })

  const children: string = (heading || title || internalName)?.toString() || capitalize(href)

  if (!href) {
    return null
  }

  const WrapperComponent = Wrapper || React.Fragment
  return (
    <WrapperComponent>
      <ChakraNextLink
        data-content-type={contentType || __typename}
        href={href}
        data-sys-id={sys?.id}
        {...(targetUrl?.startsWith('http') && { target: '_blank' })}
        {...props}
      >
        {children}
      </ChakraNextLink>
    </WrapperComponent>
  )
}

NavigationItem.displayName = TAG

export default NavigationItem
