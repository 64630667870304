import { Heading, Button, Box, Text, useDisclosure } from '@chakra-ui/react'
import React, { FunctionComponent, useContext, useId, useRef } from 'react'

import NewsletterDrawer from 'components/modules/Newsletter/NewsletterDrawer'
import { GlobalPropsContext } from 'utils/Providers/GlobalProps/context'

/**
 * Used for E2E tests.
 */
const TAG = 'Subscribe'

const Subscribe: FunctionComponent = () => {
  const descriptionId = useId()

  const { isOpen, onClose, onOpen } = useDisclosure()
  const btnRef = useRef<HTMLButtonElement>(null)

  const { subscribeHeading, subscribeText, subscribeCta } = useContext(GlobalPropsContext)

  if (!subscribeHeading && !subscribeText && !subscribeCta) {
    return null
  }

  return (
    <>
      <Box data-testid={TAG} w="full" mb={20}>
        <Heading as="h2" size="md" mb={2}>
          {subscribeHeading}
        </Heading>
        <Text mb={6} id={descriptionId}>
          {subscribeText}
        </Text>
        <Button
          data-testid={`${TAG}-button`}
          aria-haspopup="dialog"
          aria-expanded={isOpen}
          aria-controls={`chakra-${TAG}-drawer`}
          aria-label={subscribeCta}
          onClick={onOpen}
          variant="secondary"
          w="full"
          aria-describedby={descriptionId}
        >
          {subscribeCta}
        </Button>
      </Box>

      <NewsletterDrawer finalFocusRef={btnRef} isOpen={isOpen} onClose={onClose} />
    </>
  )
}

Subscribe.displayName = TAG

export default Subscribe
