export const Divider = {
  baseStyle: {
    opacity: 1,
    /**
     * We need to use !important to override the default `border: 0` style that is placed after our styles :|
     **/
    my: '8px !important',
    borderColor: 'primary.grey.100 !important',
    borderBottom: '4px !important',
  },
  variants: {
    short: {
      maxWidth: '80px',
      borderBottomWidth: '8px !important',
      borderColor: 'primary.red !important',
    },
  },
}
