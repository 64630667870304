export const sizes = {
  desktop: '1440px',
  'desktop-content': '1360px',
  'menu-content': '1136px',
  'main-block-lg': '900px',
  'main-block-md': '780px',
  'main-block': '668px',
  'drawer-lg': '592px',
  'side-block-lg': '436px',
  'side-block': '322px',
  'side-block-sm': '280px',
}
