import { default as NextHead } from 'next/head'
import React, { FunctionComponent } from 'react'

import useCanonicalURL from 'hooks/useCanonicalURL'

const TAG = 'CanonicalURL'

/**
 * Separated component because this will optimize Head rendering.
 */
const CanonicalURL: FunctionComponent = () => {
  const siteUrl = process.env.NEXT_PUBLIC_SITE_URL
  const canonicalUrl = useCanonicalURL()

  if (!siteUrl) {
    console.error('Site URL is invalid. Please set environment variable `NEXT_PUBLIC_SITE_URL`.')
    return null
  }

  if (typeof window !== 'undefined' && location.origin !== siteUrl) {
    console.error(
      'Site URL and location.origin do not match. Please set correct environment variable `NEXT_PUBLIC_SITE_URL`.',
    )
    return null
  }

  return (
    <NextHead>
      <link key="canonical" rel="canonical" href={canonicalUrl} />
    </NextHead>
  )
}

CanonicalURL.displayName = TAG

export default CanonicalURL
