import { ChakraTheme, extendTheme, theme as base } from '@chakra-ui/react'

// Component style overrides
import {
  Alert,
  Accordion,
  Button,
  Checkbox,
  Container,
  Divider,
  Drawer,
  Form,
  FormError,
  Heading,
  Input,
  Link,
  Select,
  Textarea,
  Tabs,
  List,
} from './components'
// Foundational style overrides
import { breakpoints, colors, fontSizes, sizes } from './foundations'
// Global style overrides
import { global } from './global'

const overrides: Partial<ChakraTheme> = {
  config: {
    cssVarPrefix: 'nm',
  },
  styles: {
    global,
  },
  breakpoints,
  colors,
  sizes,
  fonts: {
    heading: `Proxima Nova, Adjusted Arial Fallback, ${base.fonts?.heading}`,
    body: `Proxima Nova, Adjusted Arial Fallback, ${base.fonts?.body}`,
  },
  fontSizes,
  components: {
    Alert,
    Accordion,
    Drawer,
    Container,
    Tabs,
    Button,
    Heading,
    Select,
    Link,
    Form,
    FormError,
    Input,
    Checkbox,
    Divider,
    Textarea,
    List,
  },
}

export default extendTheme(overrides)
