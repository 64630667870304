import { Box, Flex, Link, List, ListItem, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useContext, FunctionComponent } from 'react'

import NavigationItem, { NavigationItemProps } from 'components/elements/NavigationItem'
import arrayHasValue from 'utils/arrayHasValue'
import { GlobalPropsContext } from 'utils/Providers/GlobalProps/context'
import removeEmptyValues from 'utils/removeEmptyValues'

/**
 * Used for E2E tests.
 */
const TAG = 'BottomRow'

const renewalHandler = () => window['Cookiebot'].renew()

const BottomRow: FunctionComponent = () => {
  const { t } = useTranslation()
  const { footerMetaMenu, siteTitle } = useContext(GlobalPropsContext)

  return (
    <Box data-testid={TAG} w="full" borderTopWidth={4} borderColor="primary.red" pt={4}>
      <Text>
        &copy; {new Date().getFullYear()} {siteTitle}
      </Text>
      {arrayHasValue(footerMetaMenu?.items) ? (
        <Box as="nav" mt={2} aria-label={t('components.footer.legalsLabel')}>
          <Flex gap={6} direction="row" wrap="wrap" as={List}>
            <Link as={Text} onClick={renewalHandler}>
              {t('components.footer.cookiebot-renew-link-text')}
            </Link>
            {(removeEmptyValues(footerMetaMenu?.items) as NavigationItemProps[]).map((link) => (
              <NavigationItem wrapper={ListItem} {...link} key={link?.sys?.id} />
            ))}
          </Flex>
        </Box>
      ) : null}
    </Box>
  )
}

BottomRow.displayName = TAG

export default BottomRow
