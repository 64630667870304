import { Flex, Heading, List, ListItem, VisuallyHidden } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useContext, FunctionComponent } from 'react'

import NavigationItem, { NavigationItemProps } from 'components/elements/NavigationItem'
import arrayHasValue from 'utils/arrayHasValue'
import { GlobalPropsContext } from 'utils/Providers/GlobalProps/context'
import removeEmptyValues from 'utils/removeEmptyValues'

/**
 * Used for E2E tests.
 */
const TAG = 'FooterMenu'

const FooterMenu: FunctionComponent = () => {
  const { t } = useTranslation()
  const { footerLinkLists } = useContext(GlobalPropsContext)

  return (
    <Flex data-testid={TAG} gap={6} wrap="wrap" maxW="100%" as="nav" aria-label={t('components.footer.navLabel')}>
      <VisuallyHidden>
        <Heading as="h2">{t('components.footer.nav')}</Heading>
      </VisuallyHidden>
      {removeEmptyValues(footerLinkLists?.items)?.map((footerLinkItem) =>
        arrayHasValue(footerLinkItem?.links?.items) ? (
          <Flex key={footerLinkItem.heading} flexDirection="column" w="283px" mb={{ base: 4, md: 20 }}>
            <Heading as="h3" size="sm" mb={6}>
              {footerLinkItem.heading}
            </Heading>
            <List>
              {(removeEmptyValues(footerLinkItem.links?.items) as NavigationItemProps[]).map((link) => (
                <NavigationItem
                  wrapper={ListItem}
                  display="block"
                  mb={4}
                  color="primary.black"
                  {...link}
                  key={link.sys?.id}
                />
              ))}
            </List>
          </Flex>
        ) : null,
      )}
    </Flex>
  )
}

FooterMenu.displayName = TAG

export default FooterMenu
