export const Accordion = {
  baseStyle: {
    container: {
      borderTopWidth: '4px',
      borderColor: 'primary.grey.100',
      _last: {
        borderBottomWidth: '4px',
      },
    },
    button: {
      pl: '2',
      py: '6',
    },
  },
}
