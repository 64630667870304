import { Button, Icon } from '@chakra-ui/react'
import React, { FunctionComponent, useCallback } from 'react'

import ChevronIcon from 'assets/chevron.svg?component'
import { MenuTypePropsBase } from 'components/modules/Header/Navigation/'
import { useMenu } from 'utils/Providers/Menu/hooks'
/**
 * Used for E2E tests.
 */
const TAG = 'MenuLineItemButton'

/**
 * Used for select link type
 */
type Props = Contentful.Entry<Pick<MenuTypePropsBase, 'heading' | 'contentType' | 'sys'>> & {
  isChildSubMenu?: boolean
}

const rightIcon = (
  <Icon as={ChevronIcon} display={{ base: 'flex', lg: 'none' }} boxSize={2} color="secondary.grey.900" />
)

const MenuLineItemButton: FunctionComponent<Props> = ({ heading, isChildSubMenu = false }) => {
  const { onDesktopSubmenuChange, onMobileSubmenuChange } = useMenu()
  const menuChange = isChildSubMenu ? onMobileSubmenuChange : onDesktopSubmenuChange

  const handleOnButtonClick = useCallback(() => {
    menuChange(heading)
  }, [menuChange, heading])

  return (
    <Button data-testid={TAG} variant="menu" rightIcon={rightIcon} onClick={handleOnButtonClick} fontSize="1rem">
      {heading}
    </Button>
  )
}

MenuLineItemButton.displayName = TAG

export default MenuLineItemButton
