import React, { ComponentProps, FunctionComponent } from 'react'

import PageCountryLink from 'components/modules/Header/CountryButton/PageCountryLink'
import { DEFAULT_LANGUAGE } from 'config/i18n.config'

interface PageCountryLinkListProps extends Omit<ComponentProps<typeof PageCountryLink>, 'language'> {}

/**
 * Used for E2E tests.
 */
const TAG = 'PageCountryLinkList'

const DEFAULT_LANGUAGES = [`|${DEFAULT_LANGUAGE}`]

const PageCountryLinkList: FunctionComponent<PageCountryLinkListProps> = ({ languages = [], ...props }) => (
  <>
    {(languages?.length ? languages : DEFAULT_LANGUAGES).map((language) => (
      <PageCountryLink key={language} {...props} language={language} />
    ))}
  </>
)

PageCountryLinkList.displayName = TAG

export default PageCountryLinkList
