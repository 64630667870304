export const Tabs = {
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    primary: {
      tablist: {
        borderBottom: '4px solid',
        borderColor: 'primary.grey.500',
      },
      tabpanel: {
        p: 0,
      },
      tab: {
        px: 0,
        mr: 8,
        _focus: {
          boxShadow: 'none',
        },
        borderBottom: '4px solid',
        borderColor: 'transparent',
        marginBottom: '-4px',
        _selected: {
          fontWeight: 'bold',
          borderColor: 'primary.red',
        },
      },
    },
  },
}
