export const Organization: LDJSON.Organization = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Normet',
  url: process.env.NEXT_PUBLIC_SITE_URL,
  image: process.env.NEXT_PUBLIC_SITE_URL + '/android-chrome-512x512.png',
  logo: process.env.NEXT_PUBLIC_SITE_URL + '/android-chrome-512x512.png',
  sameAS: [
    'https://www.instagram.com/normetgroup/',
    'https://www.facebook.com/NormetGroup/',
    'https://www.linkedin.com/company/normet-corporation/',
  ],
} as const
