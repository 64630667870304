import { Button, Icon, useDisclosure } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useRef } from 'react'

import SearchIcon from 'assets/search.svg?component'
import SearchDrawer from 'components/modules/Header/SearchDrawer'
/**
 * Used for E2E tests.
 */
const TAG = 'SearchButton'

const SearchButton: FunctionComponent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation()
  const btnRef = useRef<HTMLButtonElement>(null)
  return (
    <>
      <Button
        data-testid={`${TAG}-button`}
        variant="ghost"
        aria-haspopup="dialog"
        aria-expanded={isOpen}
        aria-controls={`chakra-search-${TAG}-dialog`}
        aria-label={t('components.header.searchButton.button')}
        onClick={onOpen}
      >
        <Icon aria-hidden="true" as={SearchIcon} boxSize={5} />
      </Button>
      <SearchDrawer isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef} />
    </>
  )
}

SearchButton.displayName = TAG

export default SearchButton
