import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useContext, useRef } from 'react'

import ChevronIcon from 'assets/chevron.svg?component'
import GlobeIcon from 'assets/globe.svg?component'
import RegionItem from 'components/modules/Header/CountryButton/RegionItem'
import { GlobalPropsContext } from 'utils/Providers/GlobalProps/context'
import removeEmptyValues from 'utils/removeEmptyValues'

const TAG = 'CountryButton'

type LangKey = 'en' | 'es' | 'de' | 'fi' | 'cn'

const CountryButton: FunctionComponent = () => {
  const { t, lang } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef<HTMLButtonElement>(null)

  const context = useContext(GlobalPropsContext)
  const countries = context.countryAndLanguageSelector?.items
  if (!countries?.length) {
    return null
  }

  return (
    <>
      <Drawer
        id={`${TAG}-dialog`}
        data-testid={`${TAG}-dialog`}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading as="h2" size="md">
              {t('components.header.countryButton.title')}
            </Heading>
          </DrawerHeader>
          <DrawerBody>
            <Stack spacing="6" pb="4">
              {removeEmptyValues(countries).map((region) => (
                <RegionItem
                  key={region.heading}
                  heading={region.heading}
                  links={region.links?.items}
                  closeDrawer={onClose}
                />
              ))}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Button
        data-testid={`${TAG}-button`}
        ref={btnRef}
        variant="ghost"
        aria-haspopup="dialog"
        aria-expanded={isOpen}
        aria-controls={`chakra-modal-${TAG}-dialog`}
        aria-label={t('components.header.countryButton.button')}
        onClick={onOpen}
        size="sm"
        minH={8}
        borderRadius={0}
        w={{ base: 'full', lg: 'auto' }}
        justifyContent="space-between"
        _hover={{ bg: 'secondary.grey.900' }}
        leftIcon={<Icon aria-hidden="true" as={GlobeIcon} boxSize={5} />}
        rightIcon={
          <Icon as={ChevronIcon} display={{ base: 'flex', lg: 'none' }} boxSize={2} color="secondary.grey.900" />
        }
        px={{ base: 0, lg: 3 }}
      >
        <Text display="flex" w="full">
          {t(`components.languages.${lang as LangKey}`)}
        </Text>
      </Button>
    </>
  )
}

CountryButton.displayName = TAG

export default CountryButton
