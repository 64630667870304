import { Container, Heading, HStack } from '@chakra-ui/react'
import { FunctionComponent } from 'react'

import Link from 'components/elements/Link'
import { MenuTypeProps } from 'components/modules/Header/Navigation'
import LandingPageCategoryContent from 'components/modules/Header/Submenu/LandingPageCategory/LandingPageCategoryContent'
import useResolveDynamicPage from 'utils/Providers/DynamicPage/hooks/useResolveDynamicPage'
import removeEmptyValues from 'utils/removeEmptyValues'

/**
 * Used for E2E tests.
 */
const TAG = 'LandingPageCategory'

const LandingPageCategory: FunctionComponent<
  Contentful.Entry<Pick<MenuTypeProps, 'heading' | 'slug' | 'megaMenu' | 'sys'>>
> = ({ heading, slug, megaMenu, sys }) => {
  const resolveLink = useResolveDynamicPage('')

  const categoryLink = resolveLink(sys?.id) || slug
  return (
    <Container data-testid={TAG} maxW="desktop-content" p={5} display={{ base: 'none', lg: 'block' }}>
      {categoryLink ? (
        <Heading as="h2" display="contents">
          <Link fontSize="lg" color="primary.red" fontWeight="bold" href={categoryLink}>
            {heading}
          </Link>
        </Heading>
      ) : (
        <Heading as="h2" size="md" color="primary.red">
          {heading}
        </Heading>
      )}

      <HStack spacing={16} pb={14} mt={10} alignItems="flex-start" alignContent="start">
        {removeEmptyValues(megaMenu?.items).map((item) => (
          <LandingPageCategoryContent key={item.heading} {...item} />
        ))}
      </HStack>
    </Container>
  )
}

export default LandingPageCategory
