import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Heading,
  Stack,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'
import React, { FunctionComponent } from 'react'

import ContactFormProvider from 'components/modules/Contact/ContactFormProvider'

const ContactFormDynamic = dynamic(() => import('components/modules/Contact/ContactFormDrawer'))
/**
 * Used for E2E tests.
 */
const TAG = 'ContactDrawer'

type Props = Omit<DrawerProps, 'children'> & { finalFocusRef: React.RefObject<HTMLButtonElement> }

const ContactDrawer: FunctionComponent<Props> = ({ isOpen, onClose, finalFocusRef }: Props) => {
  const { t } = useTranslation()

  return (
    <Drawer
      id={`${TAG}-dialog`}
      data-testid={`${TAG}-dialog`}
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={finalFocusRef}
      placement="right"
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader pb={0}>
          <Heading as="h2" size="md">
            {t('components.contactDrawer.title')}
            <Divider mt={5} mb={3} />
          </Heading>
        </DrawerHeader>

        <DrawerBody>
          <Stack spacing="6" pb="4">
            {isOpen && (
              <ContactFormProvider>
                <ContactFormDynamic />
              </ContactFormProvider>
            )}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

ContactDrawer.displayName = TAG

export default ContactDrawer
