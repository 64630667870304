import { createContext } from 'react'

export interface MenuContextValue {
  isOpen: boolean
  isOpenMobileButton: boolean
  onMobileButtonOpen: () => void
  childMenuKey: string
  parentMenuKey: string
  onMobileSubmenuChange: (key: string) => void
  onDesktopSubmenuChange: (key: string) => void
  closeSubmenu: () => void
  closeNavigation: () => void
}

const initialValue: MenuContextValue = {} as MenuContextValue

export const MenuContext = createContext<MenuContextValue>(initialValue)
