import { DEFAULT_LANGUAGE, LOCALES } from 'config/i18n.config'

export const CHINESE_SIMPLIFIED_LOCALE = 'zh-CN'

export const getLocale = (lang: string | string[] | undefined): string => {
  /* CHINESE_SIMPLIFIED_LOCALE is not included in the LOCALES */
  return lang && typeof lang === 'string' && (LOCALES.includes(lang) || lang === CHINESE_SIMPLIFIED_LOCALE)
    ? lang === 'cn'
      ? /* Chinese locale has been changed to 'cn' in the URL, but CHINESE_SIMPLIFIED_LOCALE should be use in Contentful requests */
        CHINESE_SIMPLIFIED_LOCALE
      : lang
    : DEFAULT_LANGUAGE
}
