import { Collapse, Container, Stack, useBreakpointValue, useOutsideClick } from '@chakra-ui/react'
import { CloseButton } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent, useContext } from 'react'
import React from 'react'

import { MenuTypeProps } from 'components/modules/Header/Navigation'
import SubmenuContentType from 'components/modules/Header/Submenu/SubmenuContentType'
import { GlobalPropsContext } from 'utils/Providers/GlobalProps/context'
import { useMenu } from 'utils/Providers/Menu/hooks'
import removeEmptyValues from 'utils/removeEmptyValues'

/**
 * Used for E2E tests.
 */
const TAG = 'Submenu'

const Submenu: FunctionComponent = () => {
  const { t } = useTranslation()
  const { headerMainMenu } = useContext(GlobalPropsContext)
  const { isOpen, parentMenuKey, closeSubmenu } = useMenu()

  const ref = React.useRef<HTMLDivElement>(null)
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  useOutsideClick({
    ref: ref,
    handler: () => isDesktop && closeSubmenu(),
  })

  const styles = {
    position: 'absolute',
    top: '113px',
    w: 'full',
    zIndex: 'overlay',
    boxShadow: 'md',
    bg: 'rgba(0,0,0,0.5)',
    height: 'calc(100% - 81px) !important', // to fill the space below the navigation menu
  } as const

  if (!headerMainMenu?.items?.length) {
    return null
  }

  return (
    <Stack data-testid={TAG} as={Collapse} in={isOpen} animateOpacity {...styles}>
      <Stack display={{ base: 'none', lg: 'block' }} bg="white" ref={ref}>
        <Container position="relative" maxW="desktop">
          <CloseButton
            aria-label={t('components.header.mobileButton.close')}
            position="absolute"
            onClick={closeSubmenu}
            color="primary.red"
            top={4}
            right={{ base: 6, xl: 12 }}
          />
          {(removeEmptyValues(headerMainMenu.items) as Contentful.Entry<MenuTypeProps>[]).map(
            (item) =>
              item.heading === parentMenuKey && <SubmenuContentType key={item.heading || item.title} {...item} />,
          )}
        </Container>
      </Stack>
    </Stack>
  )
}

export default Submenu
