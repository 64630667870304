
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { NextWebVitalsMetric } from 'next/app'
import React from 'react'

import RouteChange from 'components/modules/Google/RouteChange'
import GoogleScripts from 'components/modules/Google/Scripts'
import LanguageInit from 'components/modules/Language/Init'
import LivePreview from 'components/modules/LivePreview'
import { getComponentLayout } from 'config/layout'
import theme from 'theme'
import DynamicPageProvider from 'utils/Providers/DynamicPage'
import GlobalPropsProvider from 'utils/Providers/GlobalProps'

function App({ Component, pageProps, router }: AppPropsWithLayout) {
  const getLayout = getComponentLayout(Component, pageProps)

  const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } })

  return (
    <ChakraProvider theme={theme}>
      <GlobalPropsProvider {...pageProps.globalProps}>
        <QueryClientProvider client={queryClient}>
          <LivePreview preview={router.isPreview} pageProps={pageProps}>
            <DynamicPageProvider tree={pageProps.tree}>{getLayout(<Component {...pageProps} />)}</DynamicPageProvider>
          </LivePreview>
        </QueryClientProvider>
      </GlobalPropsProvider>
      <RouteChange />
      <GoogleScripts />
      <LanguageInit />
    </ChakraProvider>
  )
}

/**
 * @link https://nextjs.org/docs/advanced-features/measuring-performance#sending-results-to-analytics
 */
export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
  if (typeof window.gtag === 'function') {
    window.gtag('event', name, {
      event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(name === 'CLS' ? value * 1000 : value),
      event_label: id,
      non_interaction: true,
    })
  }
}

const __Page_Next_Translate__ = App


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  