import { Box, Divider, Heading, Link, List, ListItem } from '@chakra-ui/react'
import React, { Fragment, FunctionComponent, ReactNode } from 'react'

import PageCountryLinkList from 'components/modules/Header/CountryButton/PageCountryLinkList'
import hasProperty from 'utils/hasProperty'
import sortText from 'utils/sortText'

export type CountryLink = Contentful.IPageCountryFields | Contentful.IComponentNavigationItemFields

interface RegionItemProps {
  heading: ReactNode
  links: ReadonlyArray<CountryLink> | undefined
  closeDrawer: VoidFunction
}

const TAG = 'RegionItem'

export function isNavigationItem(link: CountryLink): link is Contentful.IComponentNavigationItemFields {
  return hasProperty(link, 'title') && hasProperty(link, 'targetUrl')
}

function getLinkTitle(link: CountryLink): string {
  return (isNavigationItem(link) ? link.title : link.heading || link.countryName || link.countryCode) || ''
}

export function alphabeticOrder(linkA: CountryLink, linkB: CountryLink): number {
  const linkAName = getLinkTitle(linkA)
  const linkBName = getLinkTitle(linkB)
  return sortText(linkAName, linkBName)
}

const RegionItem: FunctionComponent<RegionItemProps> = ({ heading, links, closeDrawer }) => {
  if (!links?.length) {
    return null
  }

  return (
    <Fragment>
      <Divider my="0 !important /* default is 8px */" />
      <Box data-testid={TAG}>
        {!!heading && (
          <Heading as="h3" size="md">
            {heading}
          </Heading>
        )}
        <List
          mt="6"
          mb={
            /**
             * For list with odd length, margin bottom of the last list item
             * will not expand margin bottom of the list.
             */
            links.length % 2 ? 6 : 2
          }
          sx={{ columns: 2 }}
        >
          {[...links /* Sort mutates original array */].sort(alphabeticOrder).map((link) => {
            if (isNavigationItem(link)) {
              return (
                <ListItem key={link.title} mb="4">
                  <Link href={link.targetUrl} onClick={closeDrawer}>
                    {link.title}
                  </Link>
                </ListItem>
              )
            }
            return <PageCountryLinkList key={link.heading} closeDrawer={closeDrawer} {...link} />
          })}
        </List>
      </Box>
    </Fragment>
  )
}

RegionItem.displayName = TAG

export default RegionItem
