import Head from 'next/head'
import Script from 'next/script'
import React, { FunctionComponent, memo } from 'react'

/**
 * Used for E2E tests.
 */
const TAG = 'GoogleScripts'

/**
 * `next/script` could not be used in `_document.page.tsx` as it states in documentation:
 * @link https://nextjs.org/docs/basic-features/script#script-component
 */
const GoogleScripts: FunctionComponent = () => {
  return (
    <>
      {/* Google Tag Manager */}
      {process.env.NEXT_PUBLIC_GTM_ID ? (
        <>
          <Head>
            <link rel="preconnect" href="https://www.googletagmanager.com/" crossOrigin="anonymous" />
          </Head>
          <Script
            id="google-tag-manager"
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtm.js?id=${process.env.NEXT_PUBLIC_GTM_ID}&l=dataLayer`}
          />
          <Script
            id="google-tag-manager-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
window['dataLayer'] = window['dataLayer'] || [];
window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
function gtag(){window.dataLayer.push(arguments);}
gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}', { page_path: window.location.pathname });`,
            }}
          />
          <noscript
            id="google-tag-manager-iframe"
            dangerouslySetInnerHTML={{
              __html: `<iframe
          title="Google Tag Manager"
          src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}"
          height="0" width="0" style="display: none; visibility: hidden;" />`,
            }}
          />
        </>
      ) : null}

      {/* Google Analytics */}
      {process.env.NEXT_PUBLIC_GA_ID ? (
        <>
          <Head>
            <link rel="preconnect" href="https://www.google-analytics.com/" crossOrigin="anonymous" />
          </Head>
          <Script
            id="google-analytics"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

  ga('create', '${process.env.NEXT_PUBLIC_GA_ID}', 'auto');
  ga('send', 'pageview');`,
            }}
          />
        </>
      ) : null}

      {/* Google Recaptcha Manager */}
      {process.env.NEXT_PUBLIC_RECAPTCHA_KEY ? (
        <>
          <Head>
            <link rel="preconnect" href="https://www.google.com/" crossOrigin="anonymous" />
          </Head>
          <Script
            id="google-recaptcha"
            strategy="afterInteractive"
            src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
          />
        </>
      ) : null}
    </>
  )
}

GoogleScripts.displayName = TAG

export default memo(GoogleScripts, () => true)
