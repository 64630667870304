import { Container, Link, Flex, HStack } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FunctionComponent } from 'react'

import LinkCard from 'components/elements/Cards/LinkCard'
import { MenuTypeProps } from 'components/modules/Header/Navigation'
import useResolveDynamicPage from 'utils/Providers/DynamicPage/hooks/useResolveDynamicPage'
import removeEmptyValues from 'utils/removeEmptyValues'

/**
 * Used for E2E tests.
 */
const TAG = 'LandingPageSegment'

const LandingPageSegment: FunctionComponent<Contentful.Entry<Pick<MenuTypeProps, 'heading' | 'links' | 'sys'>>> = ({
  heading,
  links,
  sys,
}) => {
  const resolveLink = useResolveDynamicPage('')
  const half = links?.items && Math.ceil(links.items.length / 2)

  return (
    <Container
      data-testid={TAG}
      maxW="menu-content"
      px={{ base: 4, lg: 6 }}
      py={{ base: 4, lg: 5 }}
      overflow="auto"
      maxH="full"
    >
      <Link as={NextLink} fontSize="lg" color="primary.red" mb={3} fontWeight="bold" href={resolveLink(sys.id)}>
        {heading}
      </Link>

      <HStack
        flexDirection={{ base: 'column', lg: 'row' }}
        spacing={{ base: '0', lg: '6' }}
        py={4}
        mb={6}
        align="flex-start"
      >
        <Flex w="full" flexWrap="wrap" maxW="main-block">
          {removeEmptyValues(links?.items)
            .slice(0, half)
            .map((item) => (
              <LinkCard key={item.slug} {...item} />
            ))}
        </Flex>
        <Flex w="full" flexWrap="wrap" maxW="main-block">
          {removeEmptyValues(links?.items)
            .slice(half)
            .map((item) => (
              <LinkCard key={item.slug} {...item} />
            ))}
        </Flex>
      </HStack>
    </Container>
  )
}

LandingPageSegment.displayName = TAG

export default LandingPageSegment
