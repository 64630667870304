import { Button } from './button'

export const Link = {
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    primary: {
      textDecoration: 'none',
      color: 'primary.red',
    },
    secondary: {
      textDecoration: 'underline',
      color: 'primary.black',
    },
    ghost: {
      textDecoration: 'none',
      color: 'primary.black',
      outline: 'none',
      _hover: {
        textDecoration: 'none',
        color: 'primary.red',
      },
      _focusVisible: {
        textDecoration: 'none',
        color: 'primary.red',
        boxShadow: 'none',
      },
    },
    button: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...Button.baseStyle,
      ...Button.variants.secondary,
      px: 4,
    },
    buttonOutline: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...Button.baseStyle,
      ...Button.variants.outline,
      px: 4,
    },
    disabled: {
      opacity: 0.5,
      cursor: 'auto',
      textDecoration: 'none',
      color: 'primary.black',
      outline: 'none',
      _hover: {
        textDecoration: 'none',
      },
      _focus: {
        boxShadow: 'none',
      },
    },
  },
}
