export const colors = {
  greyCCC: '#CCCCCC',
  grey999: '#999999',
  black: '#101111',
  red: '#D0181F',
  primary: {
    black: '#333333',
    red: '#D0181F',
    grey: {
      100: '#F2F2F2',
      500: '#E5E5E5',
    },
    white: '#FFFFFF',
  },
  secondary: {
    green: '#39B54A',
    beige: {
      100: '#F8EEE1',
      600: '#F3D9B7',
    },
    grey: {
      100: '#F7F7F7',
      300: '#3D3D3D',
      600: '#F5F5F5',
      900: '#808080',
    },
  },
}
