import { Box, Card, CardBody, Heading, Link, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import useResolveDynamicPage from 'utils/Providers/DynamicPage/hooks/useResolveDynamicPage'

type Props = CardProps & (Contentful.IPageProcess | Contentful.IPageProductCategory)

/**
 * Used for E2E tests.
 */
const TAG = 'LinkCard'

const LinkCard: FunctionComponent<Props> = ({ heading, heroContent, slug, sys }) => {
  const resolveLink = useResolveDynamicPage('')

  return (
    <Link data-testid={TAG} as={NextLink} variant="ghost" href={resolveLink(sys.id) || slug || '#'} w="100%">
      <Card
        wordBreak="break-word"
        pos="relative"
        variant="unstyled"
        direction={{ base: 'column', md: 'row' }}
        borderRadius={0}
        align="center"
        mb={5}
        gap={6}
      >
        {heroContent?.image?.url && (
          <Box width={{ base: 120, lg: 200 }}>
            <Image
              display={{ base: 'none', md: 'block' }}
              maxW="100%"
              src={heroContent.image.url}
              alt={heroContent.image.title}
              blurDataURL={heroContent.image.blurDataURL}
              focalPoint={heroContent.focalPoint}
              width={{ base: 120, lg: 200 }}
              maxWidth={{ base: 120, lg: 200 }}
              minW="100%"
              height="120"
              borderRadius={2}
            />
          </Box>
        )}

        <CardBody w="full">
          <Heading as="h4" size={{ base: '1.125rem', md: 'md' }} mb={2} _hover={{ color: 'primary.red' }} noOfLines={3}>
            {heading}
          </Heading>
          {heroContent?.caption && (
            <Text fontSize="xs" mb={4} noOfLines={4}>
              {heroContent.caption}
            </Text>
          )}
        </CardBody>
      </Card>
    </Link>
  )
}

LinkCard.displayName = TAG

export default LinkCard
