export const Button = {
  defaultProps: {
    variant: 'primary',
  },
  baseStyle: {
    minH: 12,
    fontSize: 'md',
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
    fontWeight: '700',
    _focus: {
      boxShadow: 'none',
    },
  },
  variants: {
    primary: {
      color: 'primary.white',
      backgroundColor: 'primary.red',
      borderRadius: 'sm',
      transition: 'all 0.2s',
      _hover: {
        outline: '0.25rem solid white',
        outlineOffset: '-.5rem',
        _disabled: {
          outline: 'none',
          bg: 'primary.grey.500',
        },
      },
      _focusVisible: {
        outline: '0.25rem solid white',
        outlineOffset: '-.5rem',
        _disabled: {
          outline: 'none',
          bg: 'primary.grey.500',
        },
      },
      _disabled: {
        opacity: 1,
        bg: 'primary.grey.500',
        color: 'secondary.grey.900',
      },
    },
    secondary: {
      color: 'primary.red',
      backgroundColor: 'transparent',
      borderColor: 'primary.red',
      borderWidth: 2,
      borderRadius: 'sm',
      _hover: {
        color: 'primary.white',
        backgroundColor: 'primary.red',
        textDecoration: 'none',
        _disabled: {
          bg: 'primary.grey.500',
          color: 'secondary.grey.900',
        },
      },
      _focusVisible: {
        color: 'primary.white',
        backgroundColor: 'primary.red',
        _disabled: {
          bg: 'primary.grey.500',
          color: 'secondary.grey.900',
        },
      },
      _disabled: {
        opacity: 1,
        borderColor: 'primary.white',
        bg: 'primary.grey.500',
        color: 'secondary.grey.900',
      },
    },
    outline: {
      color: 'primary.white',
      borderRadius: 'sm',
      transition: 'all 0.2s',
      borderWidth: 2,
      _hover: {
        backgroundColor: 'primary.red',
        borderColor: 'primary.red',
        textDecoration: 'none',
        _disabled: {
          outline: 'none',
          bg: 'primary.grey.500',
        },
      },
      _focusVisible: {
        backgroundColor: 'primary.red',
        borderColor: 'primary.red',
        textDecoration: 'none',
        _disabled: {
          outline: 'none',
          bg: 'primary.grey.500',
        },
      },
      _disabled: {
        opacity: 1,
        bg: 'primary.grey.500',
        color: 'secondary.grey.900',
      },
    },
    menu: {
      position: 'relative',
      fontSize: 'sm',
      fontWeight: 'bold',
      minHeight: 'auto',
      height: '3rem',
      py: 4,
      lineHeight: 1.143,
      px: { base: 0, lg: 3 },
      justifyContent: { base: 'space-between', lg: 'center' },

      _before: {
        lg: {
          content: '""',
          position: 'absolute',
          bottom: '0',
          left: '0',
          right: '0',
          width: '3rem',
          height: '3px',
          bg: 'primary.red',
          margin: 'auto',
          transform: `scale(0)`,
          transition: 'transform 0.3s',
        },
      },

      _hover: {
        textDecoration: 'none',
        _before: {
          transform: `scale(1)`,
        },
      },
      _focusVisible: {
        _before: {
          transform: `scale(1)`,
        },
      },
      _active: {
        _before: {
          transform: `scale(1)`,
        },
      },
    },
    slider: {
      display: { base: 'none', md: 'flex' },
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(51, 51, 51, 0.5)',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      transition: 'all 0.4s',
      zIndex: 'overlay',
      width: '80px',
      height: '80px',
      borderRadius: 'none',

      _hover: {
        backgroundColor: 'primary.black',
        _disabled: {
          backgroundColor: 'primary.white',
          borderColor: 'secondary.grey.900',
        },
      },
      _focusVisible: {
        backgroundColor: 'primary.black',
      },
      _disabled: {
        opacity: 0,
        cursor: 'auto',
        pointerEvents: 'none',
      },
    },
    ghost: {
      _hover: {
        backgroundColor: 'secondary.grey.100',
      },
      _focusVisible: {
        backgroundColor: 'secondary.grey.100',
      },
    },
    link: {
      color: 'primary.red',
      _hover: {
        textDecoration: 'underline',
      },
      _focusVisible: {
        textDecoration: 'underline',
      },
    },
  },
}
