import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Stack,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useContext, useMemo, useRef } from 'react'

import MenuIcon from 'assets/menu.svg?component'
import CountryButton from 'components/modules/Header/CountryButton'
import HeaderContactButton from 'components/modules/Header/HeaderContactButton'
import Logo from 'components/modules/Header/Logo'
import { MenuTypeProps } from 'components/modules/Header/Navigation'
import MenuContentType from 'components/modules/Header/Navigation/MenuContentType'
import SlideDrawer from 'components/modules/Header/Submenu/SlideDrawer'
import SubmenuContentType from 'components/modules/Header/Submenu/SubmenuContentType'
import { GlobalPropsContext } from 'utils/Providers/GlobalProps/context'
import { useMenu } from 'utils/Providers/Menu/hooks'
import removeEmptyValues from 'utils/removeEmptyValues'

/**
 * Used for E2E tests.
 */
const TAG = 'MobileMenuButton'

const MobileMenuButton: FunctionComponent = () => {
  const { t } = useTranslation()
  const { isOpenMobileButton, onMobileButtonOpen, parentMenuKey, closeNavigation, closeSubmenu } = useMenu()

  const btnRef = useRef<HTMLButtonElement>(null)

  const { headerMainMenu } = useContext(GlobalPropsContext)

  const contentTypes = useMemo(() => headerMainMenu?.items?.map((item) => item.heading) || [], [headerMainMenu])

  const headerMainMenuItems = removeEmptyValues(headerMainMenu?.items) as Contentful.Entry<MenuTypeProps>[]

  /**
   * While the navigation is closed, we want to reset all values nefore we reopen it
   */

  if (!headerMainMenu?.items?.length) {
    return null
  }

  return (
    <Stack display={{ lg: 'none' }}>
      <Button
        data-testid={`${TAG}-button`}
        ref={btnRef}
        variant="ghost"
        aria-haspopup="dialog"
        aria-expanded={isOpenMobileButton}
        aria-controls={`chakra-modal-${TAG}-dialog`}
        aria-label={t('components.header.mobileButton.button')}
        onClick={onMobileButtonOpen}
      >
        <Icon aria-hidden="true" as={MenuIcon} />
      </Button>

      <Drawer
        id={`${TAG}-dialog`}
        data-testid={`${TAG}-dialog`}
        isOpen={isOpenMobileButton}
        placement="right"
        onClose={closeNavigation}
        finalFocusRef={btnRef}
        size={{ base: 'xs', md: 'sm' }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color="primary.black" top={4} right={4} />
          <DrawerHeader>
            <Box pl={6}>
              <Logo />
            </Box>
          </DrawerHeader>
          <DrawerBody pl={4} paddingInline="10">
            <Stack spacing="0" pb="4">
              {headerMainMenuItems.map((item) => (
                <MenuContentType key={item.sys?.id || item.heading || item.title} {...item} />
              ))}
            </Stack>
            <Divider />
            <Stack alignItems="flex-start">
              <CountryButton />
              <HeaderContactButton />
            </Stack>
          </DrawerBody>

          <SlideDrawer in={contentTypes.includes(parentMenuKey)} goBack={closeSubmenu} onClose={closeNavigation}>
            {headerMainMenuItems.map(
              (item) =>
                item.heading === parentMenuKey && (
                  <SubmenuContentType key={item.sys?.id || item.heading || item.title} {...item} />
                ),
            )}
          </SlideDrawer>
        </DrawerContent>
      </Drawer>
    </Stack>
  )
}

MobileMenuButton.displayName = TAG

export default MobileMenuButton
