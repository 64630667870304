/**
 * Configuration file for layouts.
 *
 * More details here:
 * @see {GetLayoutPage}
 * @link https://nextjs.org/docs/basic-features/layouts
 */

import getLayoutMain from 'components/layouts/Main'

const LAYOUT_CONFIG_MAIN = 'Main'
export type LayoutConfigMain = typeof LAYOUT_CONFIG_MAIN

/**
 * If you want to use _WAY 2_, you should add your layout here
 * and to the switch clause in the below function.
 * Be careful with that, because by adding your layout, you're extending first chunk size.
 */
export const Layout = {
  Main: LAYOUT_CONFIG_MAIN,
} as const

/**
 * Get layout for `Component` based on prototype, props or load default main layout.
 *
 * @example
 * ```tsx
 * // WAY 1:
 * // pages/index.tsx
 * import React from 'react'
 * import getLayoutMain from 'components/layouts/Main'
 *
 * const Page: NextPageWithLayout = () => {
 *   return <span>Page content</span>
 * }
 *
 * Page.getLayout = getLayoutMain({})
 *
 * export default Page
 * ```
 *
 * ---
 *
 * @example
 * ```tsx
 * // WAY 2:
 * // pages/index.tsx
 * import type { GetStaticProps } from 'next'
 * import React from 'react'
 * import { Layout } from 'config/layout'
 *
 * const Page: NextPageWithLayout = () => {
 *   return <span>Page</span>
 * }
 *
 * // This way will work out also with `getServerSideProps`
 * export const getStaticProps: GetStaticProps<AppPageLayoutProps> = () => {
 *   return {
 *     props: {
 *       layout: {
 *         type: Layout.Main,
 *         props: {},
 *       },
 *     },
 *   }
 * }
 *
 * export default Page
 * ```
 *
 * ---
 * More details here:
 * @see {GetLayoutPage}
 * @link https://nextjs.org/docs/basic-features/layouts
 *
 * @default LayoutMain
 */
export const getComponentLayout = <PROPS extends AppPageLayoutProps>(
  Component: NextPageWithLayout<PROPS>,
  pageProps: PROPS,
): GetLayout => {
  // Layout by getLayout.
  if (typeof Component.getLayout === 'function') {
    return Component.getLayout
  }

  // Layout by pageProps from `getStaticProps` or `getServerSideProps`.
  switch (pageProps?.layout?.type) {
    case Layout.Main:
      return getLayoutMain(pageProps.layout.props)
  }

  // Layout default.
  return getLayoutMain(pageProps?.layout?.props)
}
