/**
 * Util to sort texts alphabetically.
 *
 * .localeCompare is much slower than this.
 * @link https://stackoverflow.com/a/52369951
 */
function sortText(textA: string, textB: string) {
  return textA < textB ? -1 : textA > textB ? 1 : 0
}

export default sortText
