import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Heading,
  Stack,
} from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import React, { FunctionComponent, useContext } from 'react'

import { GlobalPropsContext } from 'utils/Providers/GlobalProps/context'

const NewsletterFormDynamic = dynamic(() => import('components/modules/Newsletter/NewsletterForm'))
/**
 * Used for E2E tests.
 */
const TAG = 'NewsletterFormDrawer'

type Props = Omit<DrawerProps, 'children'> & { finalFocusRef: React.RefObject<HTMLButtonElement> }

const NewsletterFormDrawer: FunctionComponent<Props> = ({ isOpen, onClose, finalFocusRef, ...props }: Props) => {
  const { subscribeCta } = useContext(GlobalPropsContext)
  return (
    <Drawer
      id={`${TAG}-dialog`}
      data-testid={`${TAG}-dialog`}
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={finalFocusRef}
      placement="right"
      size="lg"
      {...props}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader pb={0}>
          <Heading as="h2" size="md">
            {subscribeCta}
            <Divider mt={5} mb={3} />
          </Heading>
        </DrawerHeader>

        <DrawerBody>
          <Stack spacing="6" pb="4">
            {isOpen && <NewsletterFormDynamic />}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

NewsletterFormDrawer.displayName = TAG

export default NewsletterFormDrawer
