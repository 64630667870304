import { Link, Stack, List, ListItem, Heading } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FunctionComponent } from 'react'

import NavigationItem from 'components/elements/NavigationItem'
import arrayHasValue from 'utils/arrayHasValue'
import useResolveDynamicPage from 'utils/Providers/DynamicPage/hooks/useResolveDynamicPage'
import removeEmptyValues from 'utils/removeEmptyValues'

/**
 * Used for E2E tests.
 */
const TAG = 'LandingPageCategoryContent'

const LandingPageCategoryContent: FunctionComponent<Contentful.IBlockLinkList> = ({
  heading,
  links,
  headingLink: _,
  internalName,
  sys,
  ...props
}) => {
  const resolveLink = useResolveDynamicPage('')

  // Using the headingLink.sys.id as it is an ID of a PageProductCategory; sys object passed as a param is a property of BlockLinkList object
  const stackLink = resolveLink((_ as Contentful.IPageProductCategory)?.sys?.id)
  const stackContent = heading || internalName
  return (
    <Stack data-testid={TAG} w="full" maxW="282px" mt={{ base: 4, lg: 0 }} data-sys-id={sys?.id} {...props}>
      <Stack pb={{ lg: 6 }} mb={{ base: 4, lg: 6 }} borderColor="primary.grey.100" borderBottomWidth={{ lg: 4 }}>
        {stackLink ? (
          <Heading as="h3" display="contents">
            <Link
              as={NextLink}
              fontSize="lg"
              fontWeight="bold"
              color={{ base: 'primary.red', lg: 'black' }}
              href={stackLink}
            >
              {stackContent}
            </Link>
          </Heading>
        ) : (
          <Heading as="h3" size="md" lineHeight="1.17" color={{ base: 'primary.red', lg: 'black' }}>
            {stackContent}
          </Heading>
        )}
      </Stack>

      {arrayHasValue(links?.items) ? (
        <List spacing={4}>
          {(removeEmptyValues(links?.items) as Contentful.IBlockLinkList[]).map((link) => (
            <NavigationItem wrapper={ListItem} color="primary.black" {...link} key={link?.sys?.id || link.heading} />
          ))}
        </List>
      ) : null}
    </Stack>
  )
}

export default LandingPageCategoryContent
