import { Heading, Box, Text } from '@chakra-ui/react'
import React, { FunctionComponent, useContext, useId } from 'react'

import ContactButton from 'components/modules/Contact/ContactButton'
import { GlobalPropsContext } from 'utils/Providers/GlobalProps/context'

/**
 * Used for E2E tests.
 */
const TAG = 'ContactUs'

const ContactUs: FunctionComponent = () => {
  const descriptionId = useId()
  const { contactHeading, contactText, contactCta } = useContext(GlobalPropsContext)

  if (!contactHeading && !contactText && !contactCta) {
    return null
  }

  return (
    <Box data-testid={TAG} w="full">
      <Heading as="h2" size="md" mb={2}>
        {contactHeading}
      </Heading>
      <Text mb={6} id={descriptionId}>
        {contactText}
      </Text>
      <ContactButton w="full" variant="secondary" title={contactCta} />
    </Box>
  )
}

ContactUs.displayName = TAG

export default ContactUs
