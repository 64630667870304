import { Button, useDisclosure, ButtonProps } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useRef } from 'react'

import ContactDrawer from 'components/modules/Contact/ContactDrawer'

const TAG = 'ContactButton'

type Props = ButtonProps & { title?: string }

const ContactButton: FunctionComponent<Props> = ({ title, ...rest }) => {
  const { t } = useTranslation()
  const contactDrawer = useDisclosure()
  const btnRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <Button
        ref={btnRef}
        onClick={contactDrawer.onOpen}
        data-testid={`${TAG}-button`}
        aria-haspopup="dialog"
        aria-expanded={contactDrawer.isOpen}
        aria-controls={`chakra-modal-${TAG}-dialog`}
        aria-label={t('components.contactDrawer.title')}
        w="min-content"
        {...rest}
      >
        {title || t('components.contactDrawer.title')}
      </Button>

      <ContactDrawer finalFocusRef={btnRef} {...contactDrawer} />
    </>
  )
}

export default ContactButton
