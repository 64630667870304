import { Stack } from '@chakra-ui/react'
import React, { FunctionComponent, useContext } from 'react'

import MenuContentType from 'components/modules/Header/Navigation/MenuContentType'
import { GlobalPropsContext } from 'utils/Providers/GlobalProps/context'
import removeEmptyValues from 'utils/removeEmptyValues'

/**
 * Used for E2E tests.
 */
const TAG = 'Navigation'

export interface MenuTypePropsBase
  extends Contentful.ILandingPageSegmentFields,
    Contentful.ILandingPageCategoryFields,
    Contentful.IComponentNavigationItemFields {
  contentType?: string
  sys: { id: string }
  targetReference?: {
    sys?: { id: string }
    heading?: string
  }
}
export type MenuTypeProps = Omit<MenuTypePropsBase, 'seoMetaTags'>

const Navigation: FunctionComponent = () => {
  const { headerMainMenu } = useContext(GlobalPropsContext)

  if (!headerMainMenu?.items?.length) {
    return null
  }

  return (
    <Stack data-testid={TAG} as="nav" direction="row" display={{ base: 'none', lg: 'flex' }} minW={0} pl="2">
      {(removeEmptyValues(headerMainMenu.items) as Contentful.Entry<MenuTypeProps>[]).map((item) => (
        <MenuContentType key={item.sys?.id || item.heading || item.title} {...item} />
      ))}
    </Stack>
  )
}

Navigation.displayName = TAG

export default Navigation
