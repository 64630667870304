// https://developers.google.com/recaptcha/docs/v3
import * as React from 'react'

const ERROR_SCRIPT_NOT_AVAILABLE = 'Google recaptcha is not available'

const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_KEY || ''

interface IGoogleReCaptchaV3HookReturn {
  executeReCaptcha: (action: string) => Promise<string>
}

function useGoogleReCaptcha(): IGoogleReCaptchaV3HookReturn {
  const executeReCaptcha = React.useCallback(async (action = 'LoadReCaptchaOnStart'): Promise<string> => {
    const { grecaptcha } = window
    if (!grecaptcha) {
      throw new Error(ERROR_SCRIPT_NOT_AVAILABLE)
    }

    return new Promise((resolve) => {
      grecaptcha.ready(() => {
        grecaptcha.execute(siteKey, { action }).then((token: string) => resolve(token))
      })
    })
  }, [])

  return { executeReCaptcha }
}

export default useGoogleReCaptcha
