export type EloquaFormInputs = {
  elqFormName: string
  elqSiteId: string
  elqCampaignId: string
  utm_campaign: string
  utm_source: string
  utm_medium: string
  siteURL: string
  apiError: string
}

export const eloquaFormDefaultValues = {
  elqSiteId: process.env.ELOQUA_SITE_ID,
  siteURL: process.env.NEXT_PUBLIC_SITE_URL,
} as const

export const ContactFormDefaultValues = {
  elqFormName: process.env.ELOQUA_CONTACT_FORM_NAME,
  ...eloquaFormDefaultValues,
} as const

export const NewsletterFormDefaultValues = {
  elqFormName: process.env.ELOQUA_NEWSLETTER_FORM_NAME,
  ...eloquaFormDefaultValues,
} as const
