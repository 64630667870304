import { Stack, Button } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, { FunctionComponent } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'

import SearchInput from 'components/modules/Search/SearchInput'
import { RoutePaths } from 'config/routes'

interface SearchDrawerFormProps {
  onClose: VoidFunction
  inputRef?: React.Ref<HTMLInputElement | null>
}

/**
 * Used for E2E tests.
 */
const TAG = 'SearchDrawerForm'

const SearchDrawerForm: FunctionComponent<SearchDrawerFormProps> = ({ onClose, inputRef }) => {
  const { t } = useTranslation()

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<Search.SearchForm>({
    mode: 'onChange',
  })

  const clearInput = () => setValue('query', '')

  const { push } = useRouter()
  const onSubmit: SubmitHandler<Search.SearchForm> = ({ query }) => {
    onClose()
    return push({ pathname: RoutePaths.search, query: { query: query.trim() } })
  }

  return (
    <Stack spacing={4} as="form" name={TAG} onSubmit={handleSubmit(onSubmit)} data-testid={TAG}>
      <SearchInput register={register} clearInput={clearInput} isSubmitting={isSubmitting} inputRef={inputRef} />
      <Button
        type="submit"
        variant="primary"
        colorScheme="primary"
        size="md"
        width="full"
        isDisabled={!isValid}
        isLoading={isSubmitting}
        loadingText={t('components.header.searchInput.loading')}
      >
        {t('components.header.searchInput.button')}
      </Button>
    </Stack>
  )
}

SearchDrawerForm.displayName = TAG

export default SearchDrawerForm
