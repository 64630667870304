export const List = {
  variants: {
    dashed: {
      container: {
        m: '20px !important',
      },
      item: {
        _before: {
          content: '"—"',
          color: 'primary.red',
          fontWeight: '700',
          // Added to align list indent for multiple lines lists
          marginLeft: '-20px',
          mr: '4px',
        },
      },
    },
  },
}
