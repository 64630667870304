import isEqual from 'lodash/isEqual'
import React, { FunctionComponent, ReactNode } from 'react'

import useMemoCompare from 'hooks/useMemoCompare'
import { DynamicPageContext, DynamicPageContextValue } from 'utils/Providers/DynamicPage/context'

interface DynamicPageProviderProps {
  tree: ReadonlyArray<DynamicPaths.ItemWithChildren>
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'DynamicPageProvider'

/**
 * This context stores tree to resolve links.
 *
 * @see pages\[...slug].page.tsx
 */
const DynamicPageProvider: FunctionComponent<DynamicPageProviderProps> = ({ children, tree }) => {
  /**
   * Why `useMemoCompare`? Because we don't want to update context when value is the same but reference changed.
   * Standard `useMemo` would update when nested reference changed, this one checks equality.
   */
  const value = useMemoCompare<DynamicPageContextValue>(() => ({ tree }), isEqual)

  return <DynamicPageContext.Provider value={value}>{children}</DynamicPageContext.Provider>
}

DynamicPageProvider.displayName = TAG

export default DynamicPageProvider
