/**
 * @private
 * Use @see `useRoutePaths` instead. We want to have localized routes.
 */
export const RoutePaths = {
  home: '/',
  // Can be localized but it's makes a mess in code.
  design: '/design/',
  search: '/search/',
  sitemap: '/sitemap/',
  // Cannot be localized, because they are in some way dynamic:
  contact: '/contact/',
  country: '/contact/[slug]/',
  blog: '/blog/',
  news: '/news/',
  events: '/events/',
  successStories: '/success-stories/',
  campaign: '/campaign/',
} as const

/**
 * @private
 * Use @see `useRoutePaths` instead. We want to have localized routes.
 */
export const ROUTES = {
  en: RoutePaths,
  es: RoutePaths,
  fi: RoutePaths,
  de: RoutePaths,
  cn: RoutePaths,
} satisfies Record<string, Record<RoutePathsKeys, string>>

export type RoutePathsKeys = keyof typeof RoutePaths
export type RoutePathsValues = (typeof RoutePaths)[RoutePathsKeys]
